import { useContext, useEffect } from 'react'
import { IonContent, IonPage, IonLabel, IonImg, IonCol, IonRow, IonCard, IonIcon, IonGrid, IonRouterLink } from '@ionic/react'
import Card from '../commonComponents/card'
import { AuthContext } from '../auth'
import { logoFacebook, logoInstagram, mailOpenOutline, storefrontOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'
import StandardContainer from '../commonComponents/standardContainer'
import { isLocationOpen } from '../utils'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useLocation } from 'react-router-dom'
import StandardCenterCenter from '../commonComponents/StandardCenterContainer'
import ServicesList from './servicesList'
import Testimonials from './Testimonials'
import Gallery from './Gallery'
import { Helmet } from 'react-helmet'

const AdsLandingPage = ({ openLoginModal, openRegisterModal, openScheduleModal, openWalkinModal, appointments, locations, getAppointments }) => {
  const { userData, businessId } = useContext(AuthContext)

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  let isSmall = vw > 500 ? false : true

  const setStatusBarStyleLight = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Light })
    } catch (error) {}
  }

  const setStatusBarStyleDark = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Dark })
    } catch (error) {}
  }

  useEffect(() => {
    setStatusBarStyleDark()
  }, [])

  const windowLocation = useLocation()

  useEffect(() => {
    if (windowLocation.pathname === '/home' || windowLocation.pathname === '/') {
      setStatusBarStyleDark()
    } else {
      setStatusBarStyleLight()
    }

    return setStatusBarStyleLight
  }, [windowLocation])

  return (
    <IonPage>
      <Helmet>
        <title>ClubCuts | HomePage2</title>
      </Helmet>
      {/* <IonHeader>
        <IonToolbar color="white" style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
          <IonTitle class="ion-text-center">
            <img src='assets/logoLeftBlack.png' height={120} style={{ marginTop: -45, marginBottom: -50 }} />
          </IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen={true}>
        <HeroSectionCard2 isSubscribed={userData?.isSubscribed} userData={userData} openWalkinModal={openWalkinModal} locations={locations} />
        <OurStory />
        <UnlimitedCard isSubscribed={userData?.isSubscribed} userData={userData} openWalkinModal={openWalkinModal} locations={locations} />
        <Testimonials />
        {/* <div style={{ backgroundColor: 'black' }}>
          <ServicesList businessId={businessId} locationId={process.env.REACT_APP_LOCATION_ID} />
        </div> */}
        <Gallery />
        {/* <LocationDetails data={locations} /> */}
        {/* <Testimonials /> */}
        <Footer />
      </IonContent>
    </IonPage>
  )
}

function OurStory() {
  return (
    <div style={{ backgroundColor: 'black', color: 'white' }}>
      <StandardContainer>
        <p style={{ fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 30, marginTop: 40, marginBottom: 0 }}>Our Difference</p>
        <p style={{ fontFamily: 'Montserrat, Arial', fontWeight: '500', fontSize: 16, marginBottom: 30 }}>
          Most of us don't like shelling out cash for a haircut, which leads to a few weeks of overgrown hair. ClubCuts aims to solve this by offering unlimited
          hair cuts, when you want them; all at a competitive price. So now you can look good all the time without sacrificing your wallet. You dont need a
          subscription to get a single servce either. Once you see the value, you'll never go back.{' '}
        </p>
      </StandardContainer>
    </div>
  )
}

function HeroSectionCard2({ userData, openWalkinModal, locations }) {
  let height = 500

  let leftMargin = 30

  let locationOpenText = isLocationOpen(locations?.[0])

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', marginBottom: -50, backgroundColor: 'black' }}>
      <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1 }} src={'/assets/hero3.png'}></IonImg>
      <StandardContainer padding={0}>
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            color: '#fff',
            marginTop: 0,
            width: '100%',
            maxWidth: 800,
          }}
        >
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '100%', maxWidth: 800 }}
            >
              <img src='assets/logos/CLUBCUTS_TEXT_RIGHT_WHITE.png' height={50} style={{}} />
            </div>
            <span style={{ fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 30, marginTop: 10, textShadow: '1px 1px 10px #111', fontWeight: '600' }}>
              #staycut
            </span>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>THE</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>HAIRCUT</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>YOU'VE</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>ALWAYS</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: 0 }}>
              WANTED<span style={{ color: colors.green }}>.</span>
            </h1>
            <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: -20, fontWeight: '500', fontSize: 16 }}>Walk-ins welcome | Appointments encouraged</p>
            <br></br>
            <br></br>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 10 }}>
            <IonRouterLink routerLink='/schedule' routerDirection='none'>
              <div
                style={{
                  fontFamily: 'Montserrat, Arial',
                  padding: 10,
                  fontWeight: '600',
                  fontSize: 16,
                  color: 'black',
                  backgroundColor: colors.green,
                  border: `1px solid ${colors.green}`,
                  borderRadius: 6,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                Book Now
              </div>
            </IonRouterLink>
          </div>
        </div>
      </StandardContainer>
    </div>
  )
}

function UnlimitedCard({ isSmall, isSubscribed, userData, openWalkinModal, locations }) {
  let height = 600
  if (!userData || userData == 'loading') {
    height = 550
  }

  let leftMargin = 30

  let locationOpenText = isLocationOpen(locations?.[0])

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', backgroundColor: 'black', width: '100%' }}>
      <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1 }} src={'/assets/blackBackground.png'}></IonImg>
      <StandardContainer padding={0}>
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            color: '#fff',
            marginTop: 0,
            width: '100%',
            maxWidth: 800,
          }}
        >
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, width: '100%', maxWidth: 800 }}
            >
              <img src='assets/infinitySignGold.png' height={230} style={{ marginTop: -50, marginBottom: -20 }} />
            </div>
            <span style={{ fontFamily: 'Reenie Beanie', color: colors.gold, fontSize: 30, marginTop: 10, textShadow: '1px 1px 10px #444' }}>Membership</span>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 54, marginBottom: -14, textShadow: '1px 1px 10px black' }}>UNLIMITED</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 54, marginBottom: -14, textShadow: '1px 1px 10px black' }}>
              HAIRCUTS<span style={{ color: colors.gold }}>.</span>
            </h1>
            <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: 0, fontWeight: '400', fontSize: 26 }}>
              Starting at <span style={{ fontWeight: '700', color: colors.gold }}>$43</span>
              <span style={{ fontSize: 16 }}>/mo (with prepay)</span>{' '}
            </p>
            <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: -20, fontWeight: '500', fontSize: 16 }}>
              For those who want to stay looking good all the time.
            </p>
            <br></br>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <IonRouterLink routerLink='/subscription' routerDirection='none'>
              <div
                style={{
                  fontFamily: 'Montserrat, Arial',
                  padding: 10,
                  fontWeight: '600',
                  fontSize: 16,
                  color: 'black',
                  backgroundColor: colors.gold,
                  border: `1px solid ${colors.gold}`,
                  borderRadius: 6,
                  textAlign: 'center',
                  marginTop: 20,
                }}
              >
                Unlimited Packages
              </div>
            </IonRouterLink>
          </div>
        </div>
      </StandardContainer>
    </div>
  )
}

function HoursOfOperation({ data }) {
  let schedule = data?.[0]?.standardSchedule

  if (!schedule) return null

  console.log(data)

  return (
    <div>
      <IonGrid style={{ padding: 16, marginBottom: 10, color: 'black' }}>
        <IonRow>
          <IonCol></IonCol>
          <IonCol style={{ textAlign: 'left', minWidth: 310 }}>
            <h6 style={{ marginTop: 0 }}>Hours of Operation</h6>
            <p> {isLocationOpen(data?.[0])}</p>
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((key) => (
          <IonRow key={key}>
            <IonCol></IonCol>
            <IonCol size='auto' style={{ textAlign: 'left' }}>
              <div style={{ minWidth: 130, fontWeight: '600' }}>{key}</div>
            </IonCol>
            <IonCol size='auto'>
              <div style={{ minWidth: 160, textAlign: 'left' }}>
                {schedule[key]?.enabled ? (
                  <>
                    {schedule[key]?.startTime} - {schedule[key]?.endTime}
                  </>
                ) : (
                  'Closed'
                )}
              </div>
            </IonCol>
            {/* <IonCol size='auto'>
            -
          </IonCol>
          <IonCol size='auto'>
            {schedule[key]?.startTime}
          </IonCol> */}
            <IonCol></IonCol>
          </IonRow>
        ))}
      </IonGrid>
    </div>
  )
}

function LocationDetails({ data }) {
  async function handleCopy(d) {
    try {
      if (data && data.length > 0) {
        await copyTextToClipboard(`${d[0].addressStreet1} ${d[0].addressStreet2} ${d[0].addressCity}, ${d[0].addressState} ${d[0].addressZipcode}`)
        toastifySuccess()
        // toast('Copied to Clipboard');
      } else {
        alert('data not there')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toastifySuccess = () => {
    console.log('toast')
    // toast('Copied to Clipboard');
  }

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  return (
    <>
      <StandardContainer>
        <StandardCenterCenter>
          <div
            style={{
              maxWidth: 700,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: -20,
              marginTop: -20,
              marginLeft: -10,
              marginRight: -10,
            }}
          >
            <Card>
              {/* <IonImg style={{ width: '100%', height: 180, objectFit: 'cover' }} src={'/assets/hairCut.png'} ></IonImg> */}
              {/* <div style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', maxWidth: 600, height: '100%', position: 'absolute', zIndex: 99, top: 0, color: '#fff', height: 180 }}>
            <h1 style={{ fontFamily: 'Montserrat, Arial', margin: 20, fontWeight: '800', fontSize: 40 }}>{data && data[0] && data[0].name}</h1>
          </div> */}
              <div style={{ paddingTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ height: 4, width: 40, borderRadius: 4, backgroundColor: '#e2e2e2' }}></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left', padding: 20 }}>
                <IonLabel
                  onClick={() => {
                    handleCopy(data)
                  }}
                >
                  {data && data[0] ? (
                    <>
                      <h2 style={{ fontWeight: '600', color: 'black', paddingBottom: 0 }}>{data && data[0].name}</h2>
                      {data && data[0].name ? <div style={{ marginTop: 0, marginBottom: 4, color: '#eeb544' }}>★★★★★</div> : null}
                      <p>{data && data[0].addressStreet1}</p>
                      {data && data[0].addressStreet2 ? <p>{data && data[0].addressStreet1}</p> : null}
                      <p>
                        {data && data[0].addressCity}, {data && data[0].addressState} {data && data[0].addressZipcode}
                      </p>
                      <p>{data && data[0].phoneNumber}</p>
                    </>
                  ) : null}
                </IonLabel>
                <IonRouterLink routerLink='/schedule' routerDirection='none' style={{ width: '100%' }}>
                  <div
                    style={{
                      marginTop: 20,
                      fontFamily: 'Montserrat, Arial',
                      padding: 10,
                      fontWeight: '600',
                      fontSize: 16,
                      color: 'white',
                      backgroundColor: 'black',
                      border: `1px solid black`,
                      borderRadius: 6,
                      width: `100%`,
                      textAlign: 'center',
                    }}
                  >
                    Book Now
                  </div>

                  <IonCard style={{ margin: 0, marginTop: 20 }}>
                    <IonImg style={{ width: '100%', height: 250, objectFit: 'cover', borderRadius: 20 }} src={'/assets/shopPic1.png'} />
                  </IonCard>
                </IonRouterLink>
              </div>
              <HoursOfOperation data={data} />
            </Card>
          </div>
        </StandardCenterCenter>
      </StandardContainer>
    </>
  )
}

function Footer() {
  return (
    <div style={{ backgroundColor: 'black' }}>
      <StandardContainer>
        <div style={{ width: '100%', height: 1, backgroundColor: '#ffffff55' }} />
        <br></br>
        <br></br>
        <StandardCenterCenter>
          <p style={{ color: 'white', textAlign: 'center' }}>
            2030 E County Line Rd N, <br></br>Highlands Ranch, CO 80126
          </p>
          <a href='/https://clubcuts.com/terms-and-conditions' type='page' style={{ textDecoration: 'none', color: colors.green }}>
            Terms &amp; Conditions
          </a>
          <br></br>
          <a href='/schedule' type='page' style={{ textDecoration: 'none', color: colors.green }}>
            Book an Appointment
          </a>
          <br></br>
          <a href='/subscription' type='page' style={{ textDecoration: 'none', color: colors.green }}>
            Subscriptions
          </a>
        </StandardCenterCenter>
        <div style={{ marginTop: 20 }}>
          <StandardCenterCenter>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <a href='mailto:hello@clubcuts.com'>
                    <IonIcon icon={mailOpenOutline} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
                <IonCol>
                  <a href='http://instagram.com/clubcutsbarbershop'>
                    <IonIcon icon={logoInstagram} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
                <IonCol>
                  <a href='https://facebook.com/clubcuts'>
                    <IonIcon icon={logoFacebook} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
                <IonCol>
                  <a href='https://www.google.com/maps/place/ClubCuts+Barbershop/@39.5647451,-104.9620738,15z/data=!4m6!3m5!1s0x876c85670b6e7d59:0x9cada40d15046002!8m2!3d39.5647451!4d-104.9620738!16s%2Fg%2F11t31f4nts'>
                    <IonIcon icon={storefrontOutline} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
              </IonRow>
            </IonGrid>
          </StandardCenterCenter>
        </div>
      </StandardContainer>
    </div>
  )
}

export default AdsLandingPage
