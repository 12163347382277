import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import StandardContainer from '../commonComponents/standardContainer'
import { colors } from '../theme/colors'
import { trackPromotionImpression } from '../analytics'
import StandardCenterCenter from '../commonComponents/StandardCenterContainer'
import { AnimatedCheckmark, MODES } from '../commonComponents/AnimatedCheckmark/AnimatedCheckmark'
import { fontSizes } from '../theme/typography'
import { calendar, locationSharp, person } from 'ionicons/icons'

export default function StepSuccess({ isSubscribed, scheduleData, stepNext, getAppointments, isWalkin, step, trackStep, setSubscriptionReferrer }) {
  const history = useHistory()

  useEffect(() => {
    getAppointments()
  }, [])

  useEffect(() => {
    trackStep('Success', step, scheduleData)
  }, [])

  function complete() {
    history.push('/home')
    stepNext()
  }

  return (
    <>
      {isSubscribed ? (
        <>
          <div style={{ padding: 20 }}>
            <LocationSummary location={(scheduleData && scheduleData.location) || null} />
            <BarberSummary barber={scheduleData?.barber || null} anyProfessional={scheduleData?.anyProfessional} />
            <DateAndTimeSummary
              durationMin={scheduleData && scheduleData.durationMin}
              dateTime={(scheduleData && scheduleData.dateTime) || null}
              services={scheduleData.services}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <IonLabel style={{ fontSize: fontSizes.size18, marginTop: 50, marginBottom: 20 }}>
              Success! Thanks for {`${isWalkin ? 'Checking in' : 'Booking'}`}!
            </IonLabel>
            <IonButton expand='block' style={{ width: 200 }} onClick={complete}>
              Done
            </IonButton>
          </div>
        </>
      ) : (
        <>
          {/* <GrandOpeningSale complete={complete} setSubscriptionReferrer={setSubscriptionReferrer} history={history} isWalkin={isWalkin}/> */}
          <UnlimitedCard complete={complete} setSubscriptionReferrer={setSubscriptionReferrer} history={history} isWalkin={isWalkin} scheduleData={scheduleData}/>
        </>
      )}
    </>
  )
}

function BarberSummary({ barber, anyProfessional }) {
  if (!barber) return null

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='auto'>
          <IonIcon
            icon={person}
            color='primary'
            style={{ color: 'white', height: 30, width: 30, padding: 10, borderRadius: '50%', backgroundColor: '#e6e6e6' }}
          />
        </IonCol>
        <IonCol>
          <IonRow>
            <IonLabel>{anyProfessional ? 'Any Professional' : `${barber?.firstName} ${barber?.lastName?.charAt(0)}`}</IonLabel>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

function LocationSummary({ location, setStep }) {
  if (!location) return null

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='auto'>
          <IonIcon
            icon={locationSharp}
            color='primary'
            style={{ color: 'white', height: 30, width: 30, padding: 10, borderRadius: '50%', backgroundColor: '#e6e6e6' }}
          />
        </IonCol>
        <IonCol>
          <IonRow>
            <IonLabel>{`${location.name}`}</IonLabel>
          </IonRow>
          <IonRow>
            <IonLabel
              style={{ color: '#0009' }}
            >{`${location.addressStreet1}, ${location.addressCity}, ${location.addressState} ${location.addressZipcode}`}</IonLabel>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

function DateAndTimeSummary({ dateTime, services, durationMin }) {
  if (!dateTime) return null

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='auto'>
          <IonIcon
            icon={calendar}
            color='primary'
            style={{ color: 'white', height: 30, width: 30, padding: 10, borderRadius: '50%', backgroundColor: '#e6e6e6' }}
          />
        </IonCol>
        <IonCol>
          <IonRow>
            <IonLabel>{`${dateTime.startTimeText}, ${dateTime.dateText}`}</IonLabel>
          </IonRow>
          <IonRow>
            <IonLabel style={{ color: '#0009' }}>{`${durationMin} Minutes`}</IonLabel>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

function GrandOpeningSale({ complete, setSubscriptionReferrer, history, isWalkin }) {
  let height = 470
  let leftMargin = 30

  let refe = 'ScheduleSuccessPagePromotion'
  let vers = 'V1'

  function handleSignMeUp() {
    setSubscriptionReferrer({ referrer: refe, promotionVersion: vers })
    history.push('/subscription')
  }

  useEffect(() => {
    trackPromotionImpression(refe, vers)
  }, [])

  return (
    <>
      <div style={{ borderRadius: 16, padding: 0, backgroundColor: colors.green, margin: 18, marginTop: 4, marginBottom: -10 }}>
        <h1 style={{ padding: 14, textAlign: 'center', margin: 0 }}>Success! Thanks for {`${isWalkin ? 'Checking in' : 'Booking'}`}!</h1>
      </div>

      <IonCard style={{ position: 'relative', display: 'flex', flexDirection: 'column', backgroundColor: 'black', borderRadius: 20 }}>
        <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1, borderRadius: 20 }} src={'/assets/blackBackground.png'}></IonImg>
        <StandardContainer padding={0}>
          <div
            style={{
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'absolute',
              zIndex: 99,
              top: 0,
              color: '#fff',
              marginTop: 0,
              width: '100%',
              maxWidth: 800,
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '100%', maxWidth: 800 }}
            >
              <img src='assets/logos/CLUBCUTS_WHITE.png' height={50} width={80} style={{}} />
            </div>
            <span style={{ marginLeft: leftMargin, fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 25, marginTop: -10 }}>#staycut</span>
            <h1 style={{ marginLeft: leftMargin, marginTop: 0, fontWeight: '800', fontSize: 50 }}>LOOK</h1>
            <h1 style={{ marginLeft: leftMargin, marginTop: -18, fontWeight: '800', fontSize: 50 }}>YOUR</h1>
            <h1 style={{ marginLeft: leftMargin, marginTop: -18, fontWeight: '800', fontSize: 50 }}>BEST.</h1>
            <p style={{ fontFamily: 'Montserrat, Arial', marginTop: 0, marginLeft: leftMargin, marginBottom: -6, fontWeight: '600', fontSize: 20 }}>
              UNLIMITED HAIRCUTS
            </p>
            <p style={{ fontFamily: 'Montserrat, Arial', marginTop: 0, marginLeft: leftMargin, marginBottom: -20, fontWeight: '600', fontSize: 20 }}>
              for only <span style={{ color: colors.green, fontSize: 28 }}>$43</span>/mo (with prepay)
            </p>
            <br></br>
            {/* <IonRouterLink routerLink='/subscription' routerDirection='none'> */}
            <div
              onClick={handleSignMeUp}
              style={{
                fontFamily: 'Montserrat, Arial',
                padding: 10,
                marginLeft: leftMargin,
                fontWeight: '600',
                fontSize: 16,
                color: 'black',
                backgroundColor: colors.green,
                border: `1px solid ${colors.green}`,
                borderRadius: 6,
                width: 250,
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              Yes, Sign Me Up!
            </div>
            {/* </IonRouterLink> */}
            <div
              onClick={complete}
              style={{
                fontFamily: 'Montserrat, Arial',
                padding: 10,
                marginLeft: leftMargin,
                fontWeight: '600',
                fontSize: 16,
                color: 'black',
                backgroundColor: 'white',
                border: `1px solid white`,
                borderRadius: 6,
                width: 250,
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              No Thanks
            </div>
          </div>
        </StandardContainer>
      </IonCard>
    </>
  )
}

function UnlimitedCard({ complete, setSubscriptionReferrer, history, isWalkin, scheduleData }) {
  let height = 600
  let refe = 'ScheduleSuccessPagePromotion'
  let vers = 'V2'
  let leftMargin = 20

  function handleSignMeUp() {
    setSubscriptionReferrer({ referrer: refe, promotionVersion: vers })
    history.push('/subscription/?lid='+scheduleData?.location?._id)
  }

  useEffect(() => {
    trackPromotionImpression(refe, vers)
  }, [])

  return (
    <>
      <div style={{ padding: 0, backgroundColor: 'black', color: 'white', marginTop: -22 }}>
        <StandardCenterCenter>
          {/* <div style={{margin:10, marginBottom:-10}}>
                    <IonIcon icon={checkmarkCircleOutline} style={{ color: colors.green, fontSize:60 }} />
                    </div> */}
          <div style={{ marginTop: 80 }}>
            <AnimatedCheckmark mode={MODES.SUCCESS} size={60} successColor={colors.green} />
          </div>
        </StandardCenterCenter>
        <h1 style={{ padding: 14, textAlign: 'center', margin: 0, color: '#fff' }}>Thanks for {`${isWalkin ? 'Checking in' : 'Booking'}`}!</h1>
      </div>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', marginBottom: -50, backgroundColor: 'black', width: '100%' }}>
        <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1 }} src={'/assets/blackBackground.png'}></IonImg>
        <StandardContainer padding={0}>
          <div
            style={{
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'absolute',
              zIndex: 99,
              top: 0,
              color: '#fff',
              marginTop: 0,
              width: '100%',
              maxWidth: 800,
            }}
          >
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, width: '100%', maxWidth: 800 }}
              >
                <img src='assets/infinitySignGold.png' height={180} style={{ marginBottom: -20 }} />
              </div>
              <span style={{ fontFamily: 'Reenie Beanie', color: colors.gold, fontSize: 30, marginTop: 10, textShadow: '1px 1px 10px #444' }}>Membership</span>
              <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 54, marginBottom: -14, textShadow: '1px 1px 10px black' }}>UNLIMITED</h1>
              <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 54, marginBottom: -14, textShadow: '1px 1px 10px black' }}>
                HAIRCUTS<span style={{ color: colors.gold }}>.</span>
              </h1>
              <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: 0, fontWeight: '400', fontSize: 26 }}>
                Starting at <span style={{ fontWeight: '700', color: colors.gold }}>$43</span>
                <span style={{ fontSize: 16 }}>/mo (with prepay)</span>{' '}
              </p>
              {/* <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: -20, fontWeight: '500', fontSize: 16 }}>For those who want to stay looking good all the time.</p> */}
              {/* <br></br> */}
            </div>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <div
                onClick={handleSignMeUp}
                style={{
                  fontFamily: 'Montserrat, Arial',
                  padding: 10,
                  fontWeight: '600',
                  fontSize: 16,
                  color: 'black',
                  backgroundColor: colors.gold,
                  border: `1px solid ${colors.gold}`,
                  borderRadius: 6,
                  textAlign: 'center',
                  marginTop: 20,
                }}
              >
                Yes, Sign Me Up!
              </div>
              <div
                onClick={complete}
                style={{
                  fontFamily: 'Montserrat, Arial',
                  padding: 10,
                  fontWeight: '600',
                  fontSize: 16,
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: `1px solid white`,
                  borderRadius: 6,
                  width: '100%',
                  textAlign: 'center',
                  marginTop: 10,
                }}
              >
                No Thanks
              </div>
            </div>
          </div>
        </StandardContainer>
      </div>
    </>
  )
}
