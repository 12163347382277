import { useContext, useState } from 'react'
import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonInput, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import axios from 'axios'
import { AuthContext } from '../../auth'

export default function EditPaneModal({ userData, editUserOpen, closeEditPane, closeEditPaneAndRefreshData }) {
  const [tempUserData, setTempUserData] = useState(userData)
  const [customer, setCustomer] = useState()
  const [userDataChanged, setUserDataChanged] = useState(false)
  const [emailValidationMessage, setEmailValidationMessage] = useState(false)
  let customer_id = tempUserData?._id
  const { businessData, logout } = useContext(AuthContext)

  const checkCustomerExists = async (email) => {
    try {
      let response = await axios.get(`/customer_v2/checkEmail?email=${email}`)
      console.log(response, 'response=====================')
      return response
    } catch (error) {
      console.log(error, 'error=============================')
      return false
    }
  }
  const onValueChange = async (field, value) => {
    console.log(field, value, 'valueee=========')
    const userTempData = { ...tempUserData, [field]: value }
    console.log(userTempData, 'userTempData')
    setTempUserData({ ...tempUserData, [field]: value })
    setUserDataChanged(true)
    if (field == 'email' && value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValid = emailPattern.test(value)
      if (isValid) {
        setEmailValidationMessage(false)
        try {
          const checkEmailExistsResponse = await checkCustomerExists(value)
          console.log(checkEmailExistsResponse, 'checkEmailExistsResponse')
          if (checkEmailExistsResponse.data && checkEmailExistsResponse.data && checkEmailExistsResponse.data.emailExists) {
            //check if atleast one user has the provided email

            //if emailValidationMessage then seterror message as email already exists
            setEmailValidationMessage('Email Already In Use')
            setUserDataChanged(false)
          } else {
            setEmailValidationMessage(false)
          }
          // const checkIfUserExistsWithEmailResponse = await axios.get('/checkemailValidationMessage', {
          //   value,
          // })
          // console.log(checkIfUserExistsWithEmailResponse, 'checkIfUserExistsWithEmailResponse')
        } catch (error) {
          console.log(error, 'error')
        }
      } else {
        setEmailValidationMessage('Please Enter A Valid Email')
        setUserDataChanged(false)
      }
    } else if (field == 'email') {
      setEmailValidationMessage('Email Address is required')
      setUserDataChanged(false)
    }
  }
  const handleSave = async () => {
    console.log(tempUserData, 'tempUserData')
    try {
      let response = await axios.patch(`/customer_v2?id=${customer_id}`, tempUserData)
      console.log(response, 'responseresponse')
      setTimeout(() => {
        closeEditPaneAndRefreshData()
      }, 200)
      logout()
      // setUserDataChanged(false)
    } catch (error) {
      console.log('error in updating user')
      // setUserDataChanged(false)
    }
    // await getCustomer()
  }

  return (
    <IonModal isOpen={editUserOpen}>
      {console.log(emailValidationMessage, 'emailValidationMessage')}
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Update Your Details</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => closeEditPane()}>Close</IonButton>
            {!emailValidationMessage && userDataChanged && (
              <IonButton color={'primary'} onClick={() => handleSave()}>
                Save
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <IonGrid container padding={2} spacing={8}>
          <IonGrid item sm={12} md={8}>
            <div style={{ border: '1px solid gray', borderRadius: 4 }}>
              <IonInput placeholder='john@example.com' fill='outline' value={tempUserData.email} onIonInput={(e) => onValueChange('email', e.target.value)} error={emailValidationMessage} />
            </div>
            <p style={{
              backgroundColor: 'rgba(255, 165, 0, 0.2)',
              borderRadius: '8px',
              padding: '10px',
              margin: '10px 0'
            }}>
              Changing your email will automatically log you out. Please log back in with the same password and your new email address.
            </p>
            <p style={{ marginBottom: -20, fontSize: 12, color: 'red' }}>{emailValidationMessage}</p>
          </IonGrid>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}
