import { IonIcon, IonLabel, IonRow } from '@ionic/react'
import { useContext } from 'react'
import { AuthContext } from '../../auth'
import { chevronForward } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { colors } from '../../theme/colors'

const styles = {
  headingContainer: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default function PackagesListView({ packages = [], openRegisterModal, locationName }) {
  const { firebaseUser } = useContext(AuthContext)
  const history = useHistory()

  const handleSelectedPackage = item => {
    if (!firebaseUser) {
      openRegisterModal()
    } else {
      history.push(`/subscription/${item._id}`)
    }
  }

  return (
    <>
      <div style={styles.headingContainer}>
        <h6 style={{ textAlign: 'center', fontSize: '1.6rem', margin: 0 }}>{locationName} Packages</h6>
        <p style={{ textAlign: 'center' }}>
          Get access to Unlimited Haircuts and awesome member perks
        </p>
      </div>
      {packages &&
        packages.map((packageItem) => {
          if (packageItem?.prices?.length === 0) return null
          return packageItem.enabled ? (
            <IonRow onClick={() => handleSelectedPackage(packageItem)} style={{ width: '100%' }}>
              <div
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  padding: '0.5rem 1rem',
                  border: `1px solid #d6d6d6`,
                  margin: '0.5rem 0',
                  borderRadius: '1rem',
                  backgroundColor: 'white',
                }}
              >
                <IonRow
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h4 style={{ fontWeight: 'bold', marginTop: -4 }}>{packageItem.name}</h4>

                  <h6 style={{ textAlign: 'right' }}>
                    ${packageItem.price || getCheapestMonthlyCost(packageItem.prices)}
                    <span style={{ fontWeight: '500', fontSize: 14, color: 'grey' }}>/mo</span>
                    <br></br>
                    <span style={{ fontWeight: '400', fontSize: 12, color: 'grey' }}>
                      w/ prepay
                    </span>
                  </h6>
                </IonRow>
                <div style={{ marginBottom: '1rem', marginTop: -8 }}>
                  <IonLabel style={{ lineHeight: '1.2rem' }}>{packageItem.description}</IonLabel>
                </div>
                <IonRow>
                  <ul style={{ margin: 0, width: '100%', paddingLeft: 20 }}>
                    {packageItem.features &&
                      packageItem.features.map((service, serviceIndex) => {
                        return <li>{service}</li>
                      })}
                  </ul>
                </IonRow>
                <IonRow
                  style={{
                    color: colors.primaryBlue,
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                >
                  <h6 style={{ margin: '0.5rem 1', fontWeight: '600' }}>
                    {!firebaseUser ? `Login To Get ${packageItem.name} ` : 'Learn More'}
                  </h6>
                  <IonIcon icon={chevronForward} />
                </IonRow>
              </div>
            </IonRow>
          ) : null
        })}
    </>
  )
}

function getCheapestMonthlyCost(packages) {
  let cheapestMonthlyCost = Infinity

  for (const pkg of packages) {
    let costPerMonth = pkg.amount / pkg.interval_count

    if (costPerMonth < cheapestMonthlyCost) {
      cheapestMonthlyCost = costPerMonth
    }
  }

  // Round to the nearest 2 digits
  cheapestMonthlyCost = Math.round(cheapestMonthlyCost * 100) / 100

  // If it's a whole number, return it as such
  if (Number.isInteger(cheapestMonthlyCost)) {
    return cheapestMonthlyCost
  } else {
    return cheapestMonthlyCost.toFixed(2)
  }
}
