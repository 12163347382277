import { getIdToken, signOut } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { auth } from './firebase'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState('loading')
  const [userData, setUserData] = useState('loading')
  const [lastRefreshTime, setLastRefreshTime] = useState(Date.now())
  const [businessData, setBusinessData] = useState({})
  const [locations, setLocations] = useState([])
  const [businessId, setBusinessId] = useState(process.env.REACT_APP_BUSINESS_ID)

  useEffect(() => {
    auth.onAuthStateChanged(setFirebase)
  }, [])

  useEffect(() => {
    getBusiness()
  }, [businessId])

  useEffect(() => {
      getUserData()
  }, [firebaseUser])

  async function setFirebase(fb) {
    setFirebaseUser(fb)
    axios.defaults.headers.common['Authorization'] = fb ? `Bearer ${fb.accessToken}` : null
  }

  async function getLocations() {
    let d = await axios.get(`/location_v2/publicGetListByBusinessId?businessId=${businessId}`)
    setLocations(d.data)
  }

  const getBusiness = async () => {
    try {
      //settin default business id in headers to null
      axios.defaults.headers.common['prop-businessId'] = businessId
      //get all the lists of business
      let response;
      if (process.env.REACT_APP_BUSINESS_ID) {
        response = await axios.get(`/business_v2/getPublic`)
      } else {
        response = await axios.get(`/business_v2/domain`)
      }

      console.log(response.data)

      if (response?.data?.firebaseTenantId === 'clubcuts') {
        //do nothing
      } else if (response?.data?.firebaseTenantId) {
        auth.tenantId = response?.data?.firebaseTenantId
      } else {
        //TODO For multi Business
        // throw new Error('No Tenant ID')
      }
      getLocations()
      setBusinessData(response?.data || null)
    } catch (error) {
      setBusinessData(null)
    }
  }

  async function getUserData() {
    if (firebaseUser && firebaseUser.uid) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${firebaseUser.accessToken}`
      let response = await axios.get('/customer_v2')
      setUserData(response.data)

      try {
        if (window.OpenReplay && typeof window.OpenReplay.setUserID === 'function') {
          window.OpenReplay.setUserID(response?.data?.email);
        } else {
          console.log("OpenReplay not found")
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      if(firebaseUser !== 'loading') {
        setUserData(null)
      }
    }
  }

  async function refreshToken() {
    const currentTime = Date.now()
    const timeSinceLastRefresh = currentTime - lastRefreshTime

    if (firebaseUser && timeSinceLastRefresh >= 30 * 60 * 1000) {
      const newToken = await getIdToken(firebaseUser, true)
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      setLastRefreshTime(currentTime)
    }
  }

  useEffect(() => {
    const tokenRefreshInterval = setInterval(refreshToken, 2 * 60 * 1000)

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refreshToken()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearInterval(tokenRefreshInterval)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [firebaseUser])

  function logout() {
    signOut(auth) // use auth instead of getAuth()
    setFirebaseUser(null)
    setUserData(null)
    axios.defaults.headers.common['Authorization'] = null

    // Clear refid and refidExpiration from session storage
    sessionStorage.removeItem('refid')
    sessionStorage.removeItem('refidExpiration')
    localStorage.removeItem('selectedLocationId')
  }

  return (
    <AuthContext.Provider
      value={{
        firebaseUser,
        userData,
        getUserData,
        logout,
        businessId,
        businessData,
        locations,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
