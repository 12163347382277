import React, { useEffect, useState, useContext } from 'react';
import { IonModal, IonContent } from '@ionic/react';
import SchedulingController from './Scheduler/stepController'
import { AuthContext } from './auth'

export default function ScheduleModal({scheduleModalData, setScheduleModalData, getAppointments}) {
    const { userData, businessId } = useContext(AuthContext);

    function handleClose() {
        setScheduleModalData({isOpen:false})
    }

    let customerId = userData && userData._id || null


    return (
        <IonModal isOpen={scheduleModalData.isOpen}>
            <IonContent>
                <SchedulingController isOpen={scheduleModalData.isOpen} businessId={businessId} customerId={customerId} isWalkin={scheduleModalData?.type == "walkin"} closeModal={handleClose} getAppointments={getAppointments}/>
            </IonContent>
        </IonModal>
    )
}