import React, { useState } from 'react'
import { IonInput, IonItem, IonLabel, IonToast } from '@ionic/react'

const PhoneNumberInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value)

  // Handle input changes and format the phone number
  const handleChange = (event) => {
    const rawValue = event.target.value || ''
    if (rawValue.length > 14) {
      return
    }
    const formattedValue = formatPhoneNumber(rawValue)
    setInputValue(formattedValue)
    onChange(event)
  }

  // Format phone number as (xxx) xxx-xxxx
  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)
    if (match) {
      return `${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}`
    }
    return value
  }

  return (
    <>
      <IonInput
        type='tel'
        value={inputValue}
        onIonInput={handleChange}
        maxlength={14} // Limit input length to 14 characters (including formatting characters)
      />
    </>
  )
}

export default PhoneNumberInput
