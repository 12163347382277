import PickLocationStyles from './Styles/PickLocationStyles'
import { IonIcon, IonSpinner } from '@ionic/react'
import { locationSharp } from 'ionicons/icons'

export default function StepPickLocation({ businessId, locations, updateScheduleData, stepNext, setLocation, totalSteps }) {
  function handleLocationSelection(location) {
    updateScheduleData('location', location)
    stepNext()
  }
  if (!locations || locations.length == 0)
    return (
      <div style={PickLocationStyles.loadingBox}>
        <IonSpinner name='dots' />
      </div>
    )

  return (
    <>
      <div style={PickLocationStyles.container}>
        <div style={PickLocationStyles.header}>
          <div style={PickLocationStyles.title}>Choose Location</div>
        </div>
        <div style={PickLocationStyles.content}>
          {locations.map((location, index) => (
            <LocationCard
              key={location._id}
              onClick={() => handleLocationSelection(location)}
              location={location}
              handleLocationSelection={handleLocationSelection}
            />
          ))}
        </div>
      </div>
    </>
  )
}

function LocationCard({ location, onClick }) {
  return (
    <>
      <div onClick={onClick} style={PickLocationStyles.locationCard}>
        <div style={PickLocationStyles.locationCardInner}>
          <div style={PickLocationStyles.locationCardContent}>
            <div style={PickLocationStyles.locationCardData}>
              <div style={PickLocationStyles.avatarContainer}>
                <div style={PickLocationStyles.avatar}>
                  <IonIcon
                    icon={locationSharp}
                    style={{ color: 'white', height: 20, width: 20, padding: 10, borderRadius: '50%', backgroundColor: '#bdbdbd' }}
                  />
                </div>
              </div>
              <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div style={PickLocationStyles.locationCardRow}>
                  <div style={PickLocationStyles.locationName}>{location.name}</div>
                  <div style={PickLocationStyles.locationAddress}>{location.addressStreet1}</div>
                  <div style={PickLocationStyles.locationAddress}>
                    {location.addressCity}, {location.addressState} {location.addressZipcode}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
