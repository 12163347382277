import { IonButton, IonCard, IonContent, IonItem, IonPage, IonSelect, IonSelectOption, IonSpinner, IonTitle } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import Header from '../../commonComponents/header'
import axios from 'axios'
import { colors } from '../../theme/colors'
import { AuthContext } from '../../auth'
import StandardContainer from '../../commonComponents/standardContainer'
import { trackPageEvent } from '../../analytics'
import PackagesListView from './PackagesListView'

export default function Subscription({ openRegisterModal }) {
  const { locations, userData, businessId } = useContext(AuthContext)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [subscriptionsData, setSubscriptionsData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const lidFromUrl = urlParams.get('lid')
    const storedLocationId = localStorage.getItem('selectedLocationId')
    
    if (lidFromUrl && locations.some(loc => loc._id === lidFromUrl)) {
      setSelectedLocation(lidFromUrl)
      localStorage.setItem('selectedLocationId', lidFromUrl)
      removeQueryParam('lid')
    } else if (storedLocationId && locations.some(loc => loc._id === storedLocationId)) {
      setSelectedLocation(storedLocationId)
    } else if (locations?.length > 0 && !selectedLocation) {
      // setSelectedLocation(locations[0]._id)
      // localStorage.setItem('selectedLocationId', locations[0]._id)
    }
  }, [locations])

  const removeQueryParam = (param) => {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
    window.history.replaceState({}, '', url)
  }

  const handleLocationChange = (e) => {
    const newLocationId = e.detail.value
    setSelectedLocation(newLocationId)
    localStorage.setItem('selectedLocationId', newLocationId)
  }

  const getSubscriptions = async () => {
    if (selectedLocation) {
      setLoading(true)
      try {
        const response = await axios.get(`/subscriptionPackage/publicGetList?businessId=${businessId}&locationId=${selectedLocation}`)
        setSubscriptionsData(response.data)
      } catch (error) {
        console.error('Error fetching subscriptions:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    trackPageEvent('SubscriptionsList')
    if (selectedLocation) {
      getSubscriptions()
    }
  }, [selectedLocation])

  // Check for 'lid' in URL on each render
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const lidFromUrl = urlParams.get('lid')
    if (lidFromUrl && locations.some(loc => loc._id === lidFromUrl)) {
      setSelectedLocation(lidFromUrl)
      removeQueryParam('lid')
    }
  })

  return (
    <IonPage>
      {/* <Header title='Memberships' /> */}
      <IonContent className="offWhiteBackground">
        <StandardContainer medium>
          <h1 style={{fontFamily:'Figtree', fontWeight:'600',fontSize:32, marginTop:30}}>Memberships</h1>

          {userData?.isSubscribed ? (
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 10,
                padding: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2 style={{ marginTop: 0, color: colors.black, fontWeight: '600' }}>Already a Subscriber</h2>
              <div style={{ fontSize: 14, fontWidth: '600' }}>For more detail go to your account page </div>
              <br></br>
              <IonButton routerLink='/account' routerDirection='none'>
                My Account
              </IonButton>
            </div>
          ) : (
              <NotSubscribed locations={locations} selectedLocation={selectedLocation} handleLocationChange={handleLocationChange} loading={loading} subscriptionsData={subscriptionsData} openRegisterModal={openRegisterModal}/>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}


function NotSubscribed({locations, selectedLocation, handleLocationChange, loading, subscriptionsData, openRegisterModal}) {
  return (
    <>
    {
      !selectedLocation ? (
        <div style={{ }}>
          <p>Memberships give you access to Unlimited Haircuts and awesome member perks. Please select a location to view available subscriptions.</p>
        </div>
      ) : null
    }
    {locations?.length > 1 && (
      <IonItem style={{ marginBottom: 20 }}>
        <IonSelect label='Location' placeholder='Select Location' value={selectedLocation} onIonChange={handleLocationChange}>
          {locations.map((location) => (
            <IonSelectOption key={location._id} value={location._id}>
              {location.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )}

    {!selectedLocation ? (
     null
    ) : loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <IonSpinner name='crescent' />
      </div>
    ) : (
      <PackagesListView packages={subscriptionsData} openRegisterModal={openRegisterModal} locationName={locations?.find(l => l._id === selectedLocation)?.name}/>
    )}
  </>
  )
}
