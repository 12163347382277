import './firebase'
import { Route } from 'react-router-dom'
import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import Login from './login2'
import Tabs from './Tabs'
import { AuthContext, AuthProvider } from './auth'
import { v4 as uuidv4 } from 'uuid'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
// import Tracker from '@openreplay/tracker';

// const tracker = new Tracker({
//   projectKey: process.env.REACT_APP_OPENREPLAY_PROJECT_KEY,  
// });

setupIonicReact()

const AppContent = () => {
  const { userData, firebaseUser } = useContext(AuthContext)
  const [trackedRefId, setTrackedRefId] = useState(null)
//   useEffect(() => {
//     tracker.start();
// }, [])

  useEffect(() => {
    if (window.OpenReplay && typeof window.OpenReplay.start === 'function') {
      
      window.OpenReplay.start();
      console.log('OpenReplay started')
    }
  }, [])

  let eventTracked = false

  const getCustomerId = () => {
    // Logic to retrieve user ID if logged in
    if (userData) {
      return userData._id
    }
  }

  const getDeviceId = () => {
    let deviceId = localStorage.getItem('deviceId')
    if (!deviceId) {
      deviceId = generateUniqueId() // Generate a unique device ID
      localStorage.setItem('deviceId', deviceId) // Store the device ID in local storage
    }
    return deviceId
  }

  const generateUniqueId = () => {
    return uuidv4()
  }

  const setReferralId = refid => {
    const currentTime = new Date().getTime();
    const twentyFourHoursLater = currentTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  
    sessionStorage.setItem('refid', refid);
    sessionStorage.setItem('refidExpiration', twentyFourHoursLater);
  }

  const trackEvent = async (eventType, refId) => {
    const customerId = getCustomerId() // Get the logged-in user's ID
    const deviceId = getDeviceId() // Retrieve the device ID from local storage

    const event = {
      deviceId,
      eventType,
    }
    if (customerId) {
      event.customerId = customerId
    }

    // Use trackedRefId here instead of referenceId directly
    if (refId) {
      event.referenceId = refId
    }
    // Implement logic to send the 'event' object to your backend or analytics service
    try {
      await axios.post('/customerAcquisition/impression', event)
    } catch (error) {
      // Handle error
    }
  }

  const checkRefid = () => {
    let newTrackedRefId = trackedRefId // Initialize with the current trackedRefId

    if (!trackedRefId) {
      const urlParams = new URLSearchParams(window.location.search)
      const refid = urlParams.get('refid')

      if (refid) {
        newTrackedRefId = refid
      } else {
        // Check for gclid
        const gclid = urlParams.get('gclid')
        if (gclid) {
          newTrackedRefId = 'ga'
        }
        // Check for utm_campaign
        const utmCampaign = urlParams.get('utm_campaign')
        if (utmCampaign) {
          newTrackedRefId = 'ga'
        }
        // Check for reference website
        const referrer = document.referrer.toLowerCase()
        if (referrer.includes('yelp.com')) {
          newTrackedRefId = 'y'
        } else if (referrer.includes('instagram.com')) {
          newTrackedRefId = 'i'
        } else if (referrer.includes('facebook.com')) {
          newTrackedRefId = 'fb'
        } else if (referrer.includes('google.com')) {
          newTrackedRefId = 'g'
        } else if (referrer.includes('nextdoor.com')) {
          newTrackedRefId = 'nd'
        }
      }
    }

    // Set the trackedRefId only if it has changed
    setTrackedRefId(newTrackedRefId)
    setReferralId(newTrackedRefId)

    // Now we have the refId set, call trackEvent
    trackEvent('impression', newTrackedRefId)
    // Implement further actions based on refId like tracking account creation, appointments, etc.
  }
  useEffect(() => {
    const fetchDataAndCheckRefid = async () => {
      if (firebaseUser === 'loading' || userData === 'loading') {
        // Do nothing when still loading
        return
      }

      if (!firebaseUser && !userData) {
        await checkRefid()
      } else if (firebaseUser && firebaseUser !== 'loading' && userData && userData !== 'loading') {
        await checkRefid()
      }
    }

    fetchDataAndCheckRefid()
  }, [firebaseUser, userData])

  return (
    <IonApp>
      <IonReactRouter>
          <Route exact path='/login' component={Login} />
          <Route path='/' component={Tabs} />
      </IonReactRouter>
    </IonApp>
  )
}

const App = () => {
  return (
    <AuthProvider>
        <AppContent />
    </AuthProvider>
  )
}

export default App
