import { IonIcon, IonLabel } from '@ionic/react'
import { createOutline, personOutline } from 'ionicons/icons'
import { styles } from '../Styles/ProfessionalInfoStyles'
import Avatar from '../../commonComponents/avatar'

export default function ProfessionalInfo({ mode, setStep, appointment, businessData, setEmployeeModalOpen }) {
  const ProfessionalLabel = ({ appointment }) => {
    const label =
      appointment.type === 'Walkin'
        ? ''
        : appointment.anyProfessional
        ? null
        : appointment.requestedBarber?._id === appointment.barber?._id
        ? 'Requested'
        : appointment.requestedBarber?._id
        ? `Requested ${appointment.requestedBarber.firstName} ${appointment.requestedBarber.lastName?.charAt(0)}`
        : ''

    return (
      label && (
        <div style={styles.labelContainerStyle}>
          <div style={styles.labelTextStyle}>{label}</div>{' '}
        </div>
      )
    )
  }

  return (
    <div style={styles.containerStyle}>
      <div style={styles.flexContainerStyle}>
        <div style={styles.avatarContainerStyle}>
          {!appointment?.anyProfessional ? <Avatar small employee={appointment.barber} businessId={businessData._id} /> : <IonIcon icon={personOutline} />}
        </div>
        <div style={styles.nameContainerStyle}>
          <div style={styles.flexContainerStyle}>
            <div style={styles.nameStyle}>
              {!appointment?.anyProfessional ? (
               
                <>
                  {appointment.barber.firstName} {appointment.barber.lastName?.charAt(0)}
                </>
              ) : (
                <>Any Stylist</>
              )}
            </div>
          </div>
          {['Complete'].includes(appointment?.status) ? null : <ProfessionalLabel appointment={appointment} />}
        </div>
        {['Complete'].includes(appointment.status) ? null : (
          <div onClick={() => setStep('barber')} style={{...styles.changeButtonStyle, marginRight: 4, color: 'rgb(0, 104, 222)'}}>
            <IonLabel><IonIcon icon={createOutline}></IonIcon></IonLabel>
          </div>
        )}
      </div>
    </div>
  )
}
