import { useContext, useEffect, useState } from 'react';
import { IonContent, IonSpinner, IonPage, IonTitle, IonToolbar, IonImg, IonCol, IonRow, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, IonLabel, IonList, IonItem, IonAvatar, IonIcon } from '@ionic/react';
import Card from './card';
import { AuthContext } from '../auth'

import AppointmentCard from '../commonComponents/appointmentCard';
import Header from '../commonComponents/header';
import CreateAccountCard from '../commonComponents/createAccountCard';
import BookButton from '../commonComponents/bookButton';
import HistoryNoAppointments from './history_NoAppointments';
import StandardCenterCenter from '../commonComponents/StandardCenterContainer';

export default function Appointments({ appointments, openLoginModal, openRegisterModal, openScheduleModal, loadingAppointments }) {
  const { firebaseUser } = useContext(AuthContext);
  console.log(appointments, 'appointments')
  return (
    <IonPage>
      <Header title="Appointments" />
      <IonContent fullscreen={true} className='whiteBackground'>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: 600 }}>
            <StandardCenterCenter>
              {loadingAppointments ? <IonSpinner name='dots' /> : null}
            </StandardCenterCenter>
            {firebaseUser && !loadingAppointments && appointments?.length > 0 ?
              <>
                <BookButton openScheduleModal={openScheduleModal} />
                {appointments?.map((a) => {
                  return <AppointmentCard appointment={a} key={a._id} />
                })}
              </>
              : null
            }
            {!loadingAppointments && appointments?.length === 0 && <HistoryNoAppointments />}

          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};




