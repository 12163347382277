import { useContext, useEffect, useState } from 'react'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react'
import axios from 'axios'
import Loading from '../commonComponents/loading'
import StatusPill from '../commonComponents/statusPill'
import { AuthContext } from '../auth'
import Invoice from '../sharedComponents/invoice'
import CancelAppointment from './CancelAppointment'

const AppointmentDetails = ({ match, history, getAppointments }) => {
  const { userData } = useContext(AuthContext)

  return (
    <IonPage>
      <IonHeader color={'transparent'}>
        <IonToolbar color='white' style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
          <IonButtons slot='start' style={{ color: 'black' }}>
            <IonBackButton />
          </IonButtons>
          <IonTitle class='ion-text-center' style={{ color: 'black' }}>
            Appointment
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: 600 }}>
            <BodyContent id={match.params.id} getAppointments={getAppointments} userData={userData} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

const BodyContent = ({ id, getAppointments, userData }) => {
  const [appointment, setAppointment] = useState()
  const [locationData, setLocationData] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [isCanceling, setIsCanceling] = useState(false)
  const [cardOnFile, setCardOnFile] = useState(false)
  const [cancellationCharges, setCancellationCharges] = useState(false)

  //Appointment Data
  async function getData() {
    try {
      setError(false)
      let d = await axios.get(`/appointment_v2?id=${id}`)
      setAppointment(d.data)
      setLoading(false)
    } catch (error) {
      setError(true)
    }
  }
  useEffect(() => {
    getData()
  }, [id])

  //Location Data from Appointment
  async function getLocationData() {
    if (appointment?.location) {
      let response = await axios.get(`/location_v2/publicGet?locationId=${appointment?.location._id}`)
      setLocationData(response?.data)
    }
  }
  useEffect(() => {
    getLocationData()
  }, [appointment])

  //Credit Card on File
  const getCard = async () => {
    if (appointment?.location) {
      setLoading(true)
      try {
        let response = await axios.get(`/customer_v2/cardStripe?locationId=${appointment?.location._id}`)

        setCardOnFile(response?.data?.cardOnFile || false)
        setLoading(false)
      } catch (error) {
        setCardOnFile(false)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    getCard()
  }, [appointment])

  const getCancellationCharges = () => {
    // Assuming appointment.payment.totalprice is the total price
    const isCustomerSubscribed = appointment?.customer?.isSubscribed
    const locationCancelationSettings = isCustomerSubscribed
      ? locationData?.appointmentSettings?.cancellationSettings?.subscriber
      : locationData?.appointmentSettings?.cancellationSettings?.nonSubscriber
    let totalPrice = appointment?.payment?.totalPrice
    let cancellationCharges
    // Check the type property and apply discount accordingly
    if (locationCancelationSettings?.type === 'fixed') {
      // Subtract the fixed value
      cancellationCharges = locationCancelationSettings?.value
    } else if (locationCancelationSettings?.type === 'percentage') {
      // Subtract the percentage
      const percentage = locationCancelationSettings?.value / 100
      cancellationCharges = totalPrice * percentage
    }
    setCancellationCharges(cancellationCharges)
  }

  useEffect(() => {
    getCancellationCharges()
  }, [])
  useEffect(() => {
    getCancellationCharges()
  }, [locationData, appointment])
  const handleCancel = async () => {
    const isCustomerSubscribed = appointment?.customChargeValue?.isSubscribed
    const locationCancelationSettings = isCustomerSubscribed
      ? locationData?.appointmentSettings?.cancellationSettings?.subscriber
      : locationData?.appointmentSettings?.cancellationSettings?.nonSubscriber

    setLoading(true)
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)

    const cancellationAllowed = timeDifferenceInHours > 24

    let cancelationCharge = 0
    let customizedCancelationCharge = false
    // Determine if customized cancellation charge should be applied
    if (locationCancelationSettings?.charge && cardOnFile) {
      if (!cancellationAllowed && cardOnFile && appointment?.agreedToCancelPolicy) {
        cancelationCharge = parseFloat(cancellationCharges)
        customizedCancelationCharge = false
      }
    }

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'Canceled',
        paidExternal: true,
        cancelationCharge,
        customizedCancelationCharge,
      }

      await axios.patch(`/appointment_v2/updateStatus?id=${appointment?._id}`, input)

      const paymentObjectWithCancelationCharge = {
        ...appointment?.payment,
        cancelationCharge,
      }

      await axios.patch(`/appointment_v2?id=${appointment?._id}`, {
        payment: paymentObjectWithCancelationCharge,
      })
      await getAppointments()
      await getData()

      setIsCanceling(false)
    } catch (error) {
      setIsCanceling(false)
      alert('Sorry there was an issue. Please try again later (CA-1001)')
    }
  }
  // async function handleCancel(applyCharges) {
  //   try {
  //     const locationCancelationSettings = locationData?.appointmentSettings?.cancellationSettings

  //     setIsCanceling(true)
  //     const cancelInput = {
  //       appointmentId: id,
  //       customizedCancelationCharge: false,
  //     }
  //     if (locationCancelationSettings?.charge) {
  //       cancelInput.cancelationCharge = applyCharges ? cancellationCharges : 0
  //     } else {
  //       cancelInput.cancelationCharge = 0
  //     }

  //     await axios.patch(`/appointment_v2/cancel`, cancelInput)
  //     if (applyCharges) {
  //       try {
  //         const paymentObjectWithCancelationCharge = {
  //           ...appointment?.payment,
  //         }
  //         paymentObjectWithCancelationCharge.cancelationCharge = cancellationCharges

  //         //update the payment object with no show charge in the appointment
  //         await axios.patch(`/appointment_v2?id=${appointment?._id}`, {
  //           payment: paymentObjectWithCancelationCharge,
  //         })
  //       } catch (error) {}
  //     }
  //     await getAppointments()
  //     await getData()

  //     setIsCanceling(false)
  //   } catch (error) {
  //     setIsCanceling(false)
  //     alert('Sorry there was an issue. Please try again later (CA-1001)')
  //   }
  // }

  if (loading) return <Loading />
  if (error) return <div>Sorry there was an issue. Please try again later.</div>
  if (!appointment) return <div>Still waiting...</div>

  let subtitle = 'FUTURE APPOINTMENT'
  let statusColor = 'grey'

  if (appointment.status == 'Complete' || appointment.status == 'Closed') {
    subtitle = 'APPOINTMENT'
    statusColor = '#2175ea'
  }

  if (appointment.status == 'Canceled') {
    subtitle = 'CANCELED APPOINTMENT'
    statusColor = '#ea6d21'
  }

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {appointment.services.map((a) => {
              return <span>{a?.name}&nbsp;</span>
            })}
          </IonCardTitle>
          <IonCardSubtitle>
            <StatusPill status={appointment?.status} />
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <p>
            {appointment.selectedDate} at {appointment.startTimeText}
          </p>
          <p>
            Professional: {appointment.anyProfessional ? 'Any Professional' : `${appointment?.barber?.firstName} ${appointment?.barber?.lastName?.charAt(0)}`}
          </p>
          <div style={{ height: 10, width: '100%' }}></div>
          <p>{appointment?.location?.name} Store</p>
          <p>{appointment?.location?.addressStreet1}</p>
          <p>
            {appointment?.location?.addressCity}, {appointment?.location?.addressState} {appointment?.location?.addressZipcode}
          </p>
          <div style={{ height: 10, width: '100%' }}></div>
        </IonCardContent>
      </IonCard>
      {appointment.status == 'Canceled' ? null : (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Receipt</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <Invoice appointment={appointment} hideHeader={true} />
          </IonCardContent>
        </IonCard>
      )}
      <p style={{ fontSize: 12, color: 'grey', padding: 20, marginBottom: -25, marginTop: -20 }}>APPT ID: {appointment._id}</p>
      {}
      {appointment.status == 'Scheduled' ? (
        <CancelAppointment
          cancellationCharges={cancellationCharges}
          cardOnFile={cardOnFile}
          appointment={appointment}
          isCanceling={isCanceling}
          handleCancel={handleCancel}
          locationData={locationData}
        />
      ) : null}
    </>
  )
}

export default AppointmentDetails
