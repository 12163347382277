import { IonIcon, IonLabel } from '@ionic/react'
import styles from '../Styles/AppointmentDateTimeStyles'
import { calendarOutline, createOutline, locationOutline, pencil, pencilOutline, timeOutline } from 'ionicons/icons'
import { formatDateWithWeekDay } from '../../utils'

// Styles
const containerStyle = {
  width: '100%',
  paddingTop: 16,
  paddingBottom: 16,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,
  display: 'flex',
}

const headerStyle = {
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'flex',
}

const headerTextContainerStyle = {
  alignSelf: 'stretch',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 12,
  display: 'inline-flex',
}

const headerTextStyle = {
  flex: '1 1 0',
  color: '#525252',
  fontSize: 14,
  fontFamily: 'Figtree',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: 1.4,
  wordWrap: 'break-word',
}

export default function LocationSummary({ setStep, appointment }) {


  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <div style={headerTextContainerStyle}>
          <div style={headerTextStyle}>LOCATION</div>
        </div>
      </div>
      <>
        <div style={{ ...styles.containerStyle, borderBottom: true ? 'none' : styles.containerStyle.borderBottom }}>
          <div style={styles.headerStyle}>
            <div style={styles.dateTimeContainerStyle}>
              <div style={styles.dateTimeHeaderStyle}>
                <div style={styles.iconStyle}>
                  <IonIcon style={styles.iconImageStyle} icon={locationOutline} />
                </div>
                <div style={styles.dateStyle}>{appointment?.location ? appointment?.location?.name : ''}</div>
              </div>
              <div style={styles.timeContainerStyle}>
                <div style={styles.timeHeaderStyle}>
                  <div style={styles.timeInfoStyle}>
                    <div style={styles.timeTextStyle}>
                    <div style={{...styles.durationTextStyle, marginLeft: 30, marginTop:-10, fontSize: 15}}>{appointment?.location.addressStreet1}{appointment?.location.addressStreet2 ? `, ${appointment?.location.addressStreet2}` : ''}</div>
                    <div style={{...styles.durationTextStyle, marginLeft: 30, fontSize: 15}}>{appointment?.location.addressCity}, {appointment?.location.addressState} {appointment?.location.addressZipcode}</div>
                  
                    </div>

                    </div>
                </div>
              </div>
            </div>

            {['Complete'].includes(appointment.status) ? null : (
              <div style={styles.changeButtonStyle}>
                <div onClick={() => setStep('location')} style={styles.changeTextStyle}>
                  <IonLabel><IonIcon icon={createOutline}></IonIcon></IonLabel>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  )
}
