
import CardOnFile from '../../Payment/cardOnFile'

export default function CardDetails({ selectedLocationId }) {
    return (
        <>
            <h5 style={{ marginLeft: 16 }}>Default Card On File</h5>
            <p style={{ marginLeft: 16, marginRight:16, marginTop:-6, fontSize:12 }}>A card is required to book an appointment.</p>
            <div style={{ backgroundColor: 'white', borderRadius: 10, margin: 16 }}>
                <CardOnFile selectedLocationId={selectedLocationId}/>
            </div>
        </>
    )
}