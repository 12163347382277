import {
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSpinner,
  IonToggle,
} from '@ionic/react'
import axios from 'axios'
import { notificationsOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'

export default function ManageNotifications({ userData }) {
  const [notificationOpen, setNotificationsOpen] = useState(false)
  const [textNotificationsEnabled, setTextNotificationsEnabled] = useState(false)
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false)
  const [updatingTextNotifications, setUpdatingTextNotifications] = useState(false)
  const [updatingEmailNotifications, setUpdatingEmailNotifications] = useState(false)
  const [loading, setLoading] = useState(true)

  const [userDataTemp, setUserTemp] = useState(userData)
  const openNotificationSettings = () => {
    setNotificationsOpen(true)
  }
  const closeNotificationSettings = () => {
    setNotificationsOpen(false)
  }
  const updateUserNotifications = async (field, value) => {
    let selectedUserData = { ...userDataTemp }
    const notificationSettings = {
      ...selectedUserData.notificationSettings,
      [field]: value,
    }
    setUserTemp(selectedUserData)
    selectedUserData['notificationSettings'] = notificationSettings
    console.log(selectedUserData, 'selectedUserDataselectedUserData')
    try {
      let response = await axios.patch(`/customer_v2?id=${userDataTemp?._id}`, selectedUserData)
      if (response.status == 200) {
        field === 'textNotificationsEnabled' && setUpdatingTextNotifications(false)
        field === 'emailNotificationsEnabled' && setUpdatingEmailNotifications(false)
      }
      console.log(response, 'response=============')
    } catch (error) {}
  }
  useEffect(() => {
    const notificationSettings = userData.notificationSettings
    setTextNotificationsEnabled(notificationSettings?.textNotificationsEnabled)
    setEmailNotificationsEnabled(notificationSettings?.emailNotificationsEnabled)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [userData])

  const toggleTextNotifications = value => {
    setTextNotificationsEnabled(value)
    if (textNotificationsEnabled !== value) {
      setUpdatingTextNotifications(true)
      updateUserNotifications('textNotificationsEnabled', value)
    }
  }
  const toggleEmailNotifications = value => {
    setEmailNotificationsEnabled(value)

    if (emailNotificationsEnabled !== value) {
      setUpdatingEmailNotifications(true)
      updateUserNotifications('emailNotificationsEnabled', value)
    }
  }
  return (
    <>
      <IonList inset={true} style={{ background: 'white' }} mode='ios'>
        <IonItem
          button={true}
          onClick={() => {
            openNotificationSettings(true)
          }}
        >
          <IonIcon icon={notificationsOutline} slot='start'></IonIcon>
          <IonLabel>Manage Notifications</IonLabel>
        </IonItem>
      </IonList>

      <IonModal isOpen={notificationOpen}>
        <CommonHeader
          title={'Manage Notifications'}
          closeButton={true}
          closeModal={closeNotificationSettings}
        />
        <IonContent className='regularBackground'>
          <div style={{ padding: '1rem' }}>
            <h3 style={{ fontWeight: 'bold', color: 'black' }}>Notification Settings</h3>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonSpinner name='dots' />
              </div>
            )}
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Text Notifications</IonLabel>
                </IonCol>
                <IonCol size='3'>
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={textNotificationsEnabled}
                    onIonChange={e => {
                      toggleTextNotifications(e.detail.checked)
                    }}
                  />
                </IonCol>
              </IonItem>
            </IonList>
            {updatingTextNotifications && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonSpinner name='dots' />
              </div>
            )}
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Email Notifications</IonLabel>
                </IonCol>
                <IonCol size='3'>
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={emailNotificationsEnabled}
                    onIonChange={e => {
                      toggleEmailNotifications(e.detail.checked)
                    }}
                  />
                </IonCol>
              </IonItem>
            </IonList>
            {updatingEmailNotifications && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonSpinner name='dots' />
              </div>
            )}
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}
