// styles.js

export const containerStyle = {
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'inline-flex',
}

export const headerStyle = {
  alignSelf: 'stretch',
  height: 63,
  paddingTop: 24,
  paddingLeft: 24,
  paddingRight: 24,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 10,
  display: 'flex',
}
export const headerContainerStyle = { justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }

export const headerTextStyle = {
  alignSelf: 'stretch',
  color: '#222222',
  fontSize: 32,
  fontFamily: 'Figtree',
  fontWeight: '600',
  lineHeight: '38.4px',
  wordWrap: 'break-word',
}

export const datePickerContainerStyle = {
  width: 390,
  paddingLeft: 24,
  paddingRight: 24,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  display: 'flex',
  alignSelf: 'center',
}

export const datePickerStyle = {
  alignSelf: 'stretch',
  flex: '1 1 0',
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'inline-flex',
}

export const datePickerInnerStyle = {
  width: 342,
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  display: 'inline-flex',
}

export const weekDatesContainerStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
}

export const timeSlotContainerStyle = {
  width: '100%',
  height: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 30,
  marginTop: 20,
  display: 'inline-flex',
}

export const subHeaderStyle = {
  color: '#222222',
  fontSize: 18,
  fontFamily: 'Figtree',
  fontWeight: '600',
  lineHeight: '28px',
  wordWrap: 'break-word',
}

export const availableTimesContainerStyle = {
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 10,
  display: 'inline-flex',
}

export const availableTimesInnerContainerStyle = {
  alignSelf: 'stretch',
  paddingTop: 12,
  paddingBottom: 12,
  background: 'white',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'inline-flex',
}

export const timeSlotWrapperStyle = {
  flex: '1 1 0',
  height: 36,
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 4,
  display: 'flex',
  marginLeft: 14,
  marginRight: 14,
}

export const timeSlotStyle = {
  flex: '1 1 0',
  height: 36,
  padding: 8,
  borderRadius: 100,
  border: '1px #717171 solid',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 4,
  display: 'flex',
}

export const timeIconContainerStyle = {
  width: 20,
  height: 20,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const timeTextStyle = {
  textAlign: 'center',
  color: '#717171',
  fontSize: 14,
  fontFamily: 'Figtree',
  fontWeight: '600',
  lineHeight: '20px',
  wordWrap: 'break-word',
}

export const dividerStyle = {
  width: 390,
  height: 6,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'inline-flex',
}

export const dividerInnerStyle = {
  width: 390,
  height: 6,
  background: '#F3F3F3',
}

export const timeslotcontainerStyle = {
  minWidth: '30%',
  cursor: 'pointer',
  height: 40,
  paddingTop: 6,
  paddingBottom: 6,
  border: '1px #d6d6d6 solid',
  borderRadius: 10,
  marginBottom: 6,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 10,
  display: 'flex',
}

export const timeslotInnerContainerStyle = {
  alignSelf: 'stretch',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  gap: 12,
  display: 'inline-flex',
}

export const timesloticonStyle = {
  height: 20,
  width: 20,
}

export const timeslottextStyle = {
  flex: '1 1 0',
  fontSize: 16,
  fontFamily: 'Figtree',
  fontWeight: '500',
  lineHeight: '28px',
  wordWrap: 'break-word',
}
