import { IonButton, IonCard, IonContent, IonPage, IonSpinner, IonTextarea } from '@ionic/react'
import { useContext, useState } from 'react'
import { AuthContext } from '../auth'
import StandardContainer from '../commonComponents/standardContainer'
import axios from 'axios'
import { useHistory } from 'react-router'

export default function CustomerFeedback() {
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState('')
  const { businessData, userData, getUserData } = useContext(AuthContext)
  const [submittingFeedback, setSubmittingFeedback] = useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const history = useHistory()
  const handleRatingChange = newRating => {
    setRating(newRating)
    setFeedbackSectionVisible(false)
  }
  const submitFeedback = async () => {
    setSubmittingFeedback(true)
    const urlParams = new URLSearchParams(window.location.search)

    try {
      const feedbackInput = {
        customerId: userData?._id, // Extracts the value of 'id' parameter
        reviewId: urlParams.get('id'), // Extracts the value of 'reviewId' parameter
        rating: parseInt(urlParams.get('rating')), // You can set a default value or extract it from the URL if needed
        businessId: urlParams.get('businessId'), // Extracts the value of 'businessId' parameter
        locationId: urlParams.get('locationId'), // Extracts the value of 'locationId' parameter
        feedback: feedback,
      }

      const response = await axios.post('/location_v2/feedback', feedbackInput)

      if (response.status === 200) {
        setSubmittingFeedback(false)
        setFeedbackSubmitted(true)
      }
    } catch (error) {}
    // Handle rating submit logic here
  }

  const [feedbackSectionVisible, setFeedbackSectionVisible] = useState(false)

  return (
    <IonPage>
      <IonContent>
        <StandardContainer>
          <IonCard style={{ marginTop: '5rem', padding: '1rem' }}>
            {!feedbackSubmitted && (
              <>
                <h1 style={{ fontWeight: 'bold', color: 'black' }}>
                  Please tell us more about how we can improve your experience
                </h1>
                <IonTextarea
                  style={{
                    height: '13rem',
                    border: '1px solid #bfbfbf',
                    borderRadius: '10px',
                    padding: '1rem',
                    marginBottom: '1rem',
                    marginTop: '2rem',
                  }}
                  value={feedback}
                  placeholder='Please tell us more...'
                  onIonChange={e => setFeedback(e.detail.value)}
                />
                <IonButton onClick={submitFeedback} disabled={!feedback.trim()} expand='full'>
                  {submittingFeedback && <IonSpinner name='lines' />}
                  Submit Feedback
                </IonButton>
              </>
            )}
            {feedbackSubmitted && (
              <>
                <h2>Thank You for Your Feedback!</h2>
                <p>
                  We greatly appreciate you taking the time to share your thoughts with us. Your
                  feedback is invaluable and helps us improve our services to better meet your
                  needs.
                </p>
                <p>
                  Our team is dedicated to delivering the best experience possible, and your
                  insights contribute to that ongoing effort. If you have any further comments or
                  suggestions, please feel free to reach out to us.
                </p>
                <IonButton routerLink='/' style={{ width: '100%' }}>
                  Back To Home
                </IonButton>
              </>
            )}
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
