import {
  IonButton,
  IonCard,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonTextarea,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'
import { cancelSubscriptionOptions } from '../../StaticData'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/standardContainer'
import StandardCenterCenter from '../../commonComponents/StandardCenterContainer'
import { AuthContext } from '../../auth'
import { calculateNearestFutureSubscriptionEndDate } from '../../utils'

export default function CancelSubscription({ match }) {
  const subscription_id = match?.params?.id
  const cancelOptions = cancelSubscriptionOptions
  const { getUserData } = useContext(AuthContext)
  const [selectedValue, setSelectedValue] = useState('')
  const [cancellingMembership, setCancellingMembership] = useState(false)
  const [cancelledMembership, setCancelledMembership] = useState(false)
  const [subscriptionData, setSubscription] = useState(false)
  const [reason, setReason] = useState('')
  const history = useHistory()
  const handleChange = event => {
    setSelectedValue(event.detail.value)
  }
  const handleReason = event => {
    setReason(event.target.value)
  }
  const getSubscription = async () => {
    try {
      let response = await axios.get(`/subscription?id=${subscription_id}`)
      if (response && response.data) {
        setSubscription(response.data)
      }
      console.log(response.data, 'response.data===================')
    } catch (error) {
      console.log(error, 'error===================')
    }
  }
  let nearestEndDate = false

  if (subscriptionData) {
    nearestEndDate = calculateNearestFutureSubscriptionEndDate(
      subscriptionData?.startDate,
      subscriptionData?.period?.toLowerCase(),
    )
  }

  const handleCancelMembership = async () => {
    setCancellingMembership(true)
    try {
      const response = await axios.patch(
        `/subscription/cancel?id=${subscription_id}&customerId=${subscriptionData.customerId}`,
        {
          cancellationReason: selectedValue,
          writtenFeedback: selectedValue == 'other' ? reason : '',
          cancellationType: 'endOfPeriod',
          cancellationDate: nearestEndDate,
        },
      )

      if (response?.data?.success) {
        getUserData()
        setCancelledMembership(true)
      }
      // getSubscription()
    } catch (error) {
      alert(error)
    }
  }
  const handleGoBackHome = () => {
    getUserData()
    setCancellingMembership(false)
    setCancelledMembership(false)
    history.push('/')
  }
  useEffect(() => {
    getSubscription()
  }, [])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Cancel Membership'} backIcon={!cancelledMembership}></CommonHeader>
      <IonContent>
        {cancelledMembership && (
          <StandardContainer>
            <IonCard style={{ padding: '1rem' }}>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Membership Cancelled</h5>
              <IonRow style={{ paddingBottom: '0.8rem' }}>
                <IonLabel style={{ fontWeight: 'bold' }}>Sorry to see you go</IonLabel>
              </IonRow>
              <IonButton onClick={handleGoBackHome} style={{ width: '100%' }}>
                Back To Home
              </IonButton>
            </IonCard>
          </StandardContainer>
        )}
        {!cancelledMembership && (
          <StandardCenterCenter>
            <IonCard style={{ padding: '1rem' }}>
              <>
                <h5 style={{ color: 'black', fontWeight: 'bold' }}>Cancel Membership</h5>
                <IonLabel>Let us know why you are cancelling your membership</IonLabel>
                <IonGrid>
                  <IonRadioGroup value={selectedValue} onIonChange={handleChange}>
                    {cancelOptions.map((item, index) => (
                      <IonRow
                        key={index}
                        style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}
                        onClick={() => setSelectedValue(item.value)}
                      >
                        <IonLabel
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px', // Adjust the gap size as needed
                            cursor: 'pointer',
                          }}
                        >
                          <IonRadio slot='start' checked={selectedValue === item.value} mode='md' />
                          {item.label}
                        </IonLabel>
                      </IonRow>
                    ))}
                  </IonRadioGroup>
                </IonGrid>

                {selectedValue == 'other' && (
                  <IonTextarea
                    value={reason}
                    onIonInput={handleReason}
                    style={{
                      padding: '1rem',
                      border: '1px solid #ccc', // Example border style
                      borderRadius: '5px', // Example border radius
                      width: '100%', // Example width
                      marginBottom: '1rem',
                      minHeight: '6rem',
                    }}
                    placeholder='Please Explain'
                  ></IonTextarea>
                )}
                <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {cancellingMembership && (
                    <IonButton
                      expand='block'
                      onClick={handleCancelMembership}
                      disabled={!selectedValue}
                    >
                      <IonSpinner name='lines' />
                      <IonLabel>Cancelling Membership</IonLabel>
                    </IonButton>
                  )}
                </IonRow>
                {!cancellingMembership && (
                  <IonButton
                    expand='block'
                    onClick={handleCancelMembership}
                    disabled={!selectedValue}
                  >
                    <IonLabel>Submit and Cancel Membership</IonLabel>
                  </IonButton>
                )}
              </>
            </IonCard>
          </StandardCenterCenter>
        )}
      </IonContent>
    </IonPage>
  )
}
