import {
  IonButton,
  IonContent,
  IonLabel,
  IonPage,
  IonSpinner,
  IonRow,
  IonGrid,
  IonCheckbox,
  IonRouterLink,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import Header from '../../commonComponents/header'
import axios from 'axios'
import { colors } from '../../theme/colors'
import StandardContainer from '../../commonComponents/standardContainer'
import SuccessComponent from './success'
import { AuthContext } from '../../auth'
import CardOnFile from '../../Payment/cardOnFile'
import { trackSubscribed, trackPageEvent } from '../../analytics'
import { calculateCostPerMonth } from '../../utils'

export default function SubscriptionDetails({ match, subscriptionReferrer }) {
  let id = match.params.id
  const { businessId, businessData } = useContext(AuthContext)
  const [subscription, setSubscription] = useState({ data: null, error: null, loading: true })
  const [cardOnFile, setCardOnFile] = useState()
  const [subscribingStatus, setSubscribingStatus] = useState()
  const { getUserData, userData } = useContext(AuthContext)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [selectedTermPlan, setSelectedTermPlan] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  useEffect(() => {
    getSubscription()
  }, [id])

  async function getSubscription() {
    let response = await axios.get(`/subscriptionPackage/?id=${id}`)
    console.log(response.data, 'response.data===================')
    setSubscription({ data: response.data, error: null, loading: false })
  }

  const handleSelectedTermPlan = item => {
    setSelectedTermPlan(item)
  }

  const onTokenizationSuccess = async () => {
    setSubscribingStatus('tokenGenerated')
    setErrorMessage(null)
    try {
      setSubscribingStatus('cardAddedToAccount')
      const periodValue = `Every ${
        selectedTermPlan.interval_count === 1 ? '' : selectedTermPlan.interval_count
      } ${
        selectedTermPlan.interval_count > 1
          ? `${selectedTermPlan.interval}s`
          : selectedTermPlan.interval
      }`

      const createSubscriptionInput = {
        customerId: userData?._id,
        subscriptionId: subscription?.data?._id,
        period: periodValue,
        businessId: businessId,
        stripeSubscriptionPlanId: selectedTermPlan.stripePriceId,
        price: selectedTermPlan.amount,
        locationId: subscription?.data?.locationId,
      }
      const response = await axios.post('/subscription', createSubscriptionInput)

      if (response.status === 200) {
        getUserData()
        trackSubscribed(subscriptionReferrer?.referrer, subscriptionReferrer?.promotionVersion)
        setSubscribingStatus('complete')
      }
    } catch (error) {
      setSubscribingStatus(null)
      let message = error?.response?.data?.error?.raw?.message || 'Failed'
      setErrorMessage('There was an issue subscribing to the package. ' + message + ' (PA-1000)')
    }
  }
  const handleAcceptTerms = () => {
    setAcceptedTerms(!acceptedTerms)
  }
  useEffect(() => {
    trackPageEvent('SubscriptionDetails')
  }, [])

  console.log(subscribingStatus, 'subscribingStatus')

  return (
    <IonPage>
      <Header title='Subscriptions' />
      <IonContent className='whiteBackground'>
        <StandardContainer>
          {subscription.loading ? (
            <IonSpinner name='dots' />
          ) : (
            <>
              {subscribingStatus == 'complete' ? (
                <SuccessComponent
                  title='Success!'
                  subTitle={`You are subscribed to ${subscription?.data?.name}`}
                  buttonLink='/'
                />
              ) : (
                <>
                  {subscription && (
                    <div>
                      <IonRow
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                        }}
                      >
                        <h6 style={{ fontWeight: '500' }}>M E M B E R S H I P</h6>
                        <h1
                          style={{
                            margin: '0',
                            fontSize: '2rem',
                            fontWeight: 'bold',
                            marginTop: -4,
                          }}
                        >
                          {subscription?.data?.name}
                        </h1>
                      </IonRow>
                      <h4 style={{ lineHeight: '1.8rem', marginBottom: '1rem', fontWeight: '400' }}>
                        {subscription?.data?.description}
                      </h4>

                      <IonRow style={{ margin: '0 0rem' }}>
                        <ul style={{ margin: '0', width: '100%' }}>
                          {subscription?.data?.features?.map((service, serviceIndex) => {
                            return (
                              <li>
                                <h6 style={{ fontWeight: '500', margin: 0, marginBottom: 4 }}>
                                  {service}
                                </h6>
                              </li>
                            )
                          })}
                        </ul>
                      </IonRow>
                    </div>
                  )}
                  <br />
                  <h4>1. Select Your Plan</h4>
                  {subscription?.data?.prices?.map((subscription, index) => {
                    return subscription.active ? (
                      <div
                        onClick={() => handleSelectedTermPlan(subscription)}
                        style={{
                          margin: '1rem 0',
                          padding: '0.5rem 1rem',
                          border: `${
                            subscription.stripePriceId === selectedTermPlan.stripePriceId
                              ? `2px solid ${colors.primaryBlue}`
                              : '1px solid grey'
                          }`,
                          borderRadius: '1rem',
                          backgroundColor: `${
                            subscription.stripePriceId === selectedTermPlan.stripePriceId
                              ? colors.primaryBlue05
                              : 'white'
                          }`,
                        }}
                      >
                        <IonGrid>
                          <IonRow
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <IonRow>
                              <h5 style={{ fontWeight: 'bold' }}>{subscription.name}</h5>
                            </IonRow>
                            <IonRow>
                              {subscription.amount && (
                                <IonRow>
                                  <h6 style={{ fontWeight: 'bold', color: 'black', marginTop: 0 }}>
                                    $
                                    {calculateCostPerMonth(
                                      subscription.amount,
                                      subscription.interval,
                                      subscription.interval_count,
                                    )}
                                    <span style={{ fontWeight: '500' }}>/mo</span>
                                  </h6>
                                </IonRow>
                              )}
                            </IonRow>
                          </IonRow>
                          {subscription.amount && (
                            <IonRow style={{ display: 'flex', justifyContent: 'end' }}>
                              <IonLabel
                                style={{
                                  fontSize: '0.8rem',
                                  color: 'grey',
                                  lineHeight: '1.2rem',
                                  fontWeight: '500',
                                  marginTop: -20,
                                  marginBottom: 10,
                                }}
                              >
                                ${Math.abs(subscription.amount)} billed every{' '}
                                {subscription.interval_count == 1
                                  ? ''
                                  : subscription.interval_count}{' '}
                                {subscription.interval_count > 1
                                  ? `${subscription.interval}s`
                                  : subscription.interval}
                              </IonLabel>
                            </IonRow>
                          )}
                        </IonGrid>
                      </div>
                    ) : null
                  })}
                  {selectedTermPlan ? (
                    <>
                      <h4>2. Credit Card</h4>
                      <div style={{ margin: '1rem 0' }}>
                        <CardOnFile
                          selectedLocationId={subscription?.data?.locationId}
                          setIsCardOnFile={setCardOnFile}
                          buttonText='Add Payment Method'
                        />
                      </div>
                    </>
                  ) : null}
                  {selectedTermPlan && cardOnFile && (
                    <>
                      {/* <h4 style={{ margin: 0, marginTop:40, marginBottom:-20, fontWeight: 'bold' }}>3. Review Payment</h4> */}
                      <IonGrid
                        style={{
                          padding: '1rem',
                          backgroundColor: colors.regularBackground,
                          margin: -20,
                          marginTop: 40,
                        }}
                      >
                        <IonRow>
                          <h4 style={{ margin: 0, fontWeight: 'bold' }}>3. Review Payment</h4>
                        </IonRow>

                        <IonRow style={{ display: 'flex', flexDirection: 'column' }}>
                          <h5 style={{ fontWeight: 'bold' }}>
                            {subscription?.data?.name} ({selectedTermPlan.interval_count}{' '}
                            {selectedTermPlan.interval})
                          </h5>
                          <h1 style={{ margin: '10px 0', fontWeight: 'bold' }}>
                            ${Math.abs(selectedTermPlan.amount)}
                          </h1>

                          <h6 style={{ margin: 0, fontWeight: '600' }}>
                            billed every{' '}
                            {selectedTermPlan.interval_count == 1
                              ? ''
                              : selectedTermPlan.interval_count}{' '}
                            {selectedTermPlan.interval_count > 1
                              ? `${selectedTermPlan.interval}s`
                              : selectedTermPlan.interval}
                          </h6>
                        </IonRow>
                        <IonRow
                          style={{ margin: '1.5rem 0', display: 'flex', alignItems: 'center' }}
                        >
                          <IonCheckbox
                            checked={acceptedTerms}
                            onIonChange={() => handleAcceptTerms()}
                          ></IonCheckbox>
                          <h6
                            onClick={() => handleAcceptTerms()}
                            style={{
                              cursor: 'pointer',
                              margin: '0px 0px 0px 10px',
                              fontSize: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            I have read and accept the{' '}
                          </h6>
                          <h6
                            style={{
                              margin: '0px 0px 0px 5px ',
                              fontSize: '10px',
                              color: 'blue',
                            }}
                          >
                            <IonRouterLink
                              href={businessData?.termsAndConditionsLink}
                              target='_blank'
                            >
                              Terms and Conditions
                            </IonRouterLink>
                          </h6>
                        </IonRow>
                        <div>
                          {!subscribingStatus ? (
                            <>
                              {cardOnFile ? (
                                <IonButton
                                  disabled={!acceptedTerms}
                                  expand='block'
                                  style={{ margin: '1rem 0' }}
                                  onClick={onTokenizationSuccess}
                                >
                                  Subscribe and Pay ${Math.abs(selectedTermPlan.amount)}
                                </IonButton>
                              ) : null}
                            </>
                          ) : subscribingStatus == 'tokenGenerated' ? (
                            <>
                              <IonSpinner name='dots' />
                              <p>Uploading Payment Details</p>
                            </>
                          ) : subscribingStatus == 'cardAddedToAccount' ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <IonSpinner name='dots' />
                              <p>Subscribing to {subscription.data.name} </p>
                            </div>
                          ) : (
                            <p>
                              {subscribingStatus && subscribingStatus != 'failure'
                                ? subscribingStatus
                                : ''}
                            </p>
                          )}
                          {errorMessage && (
                            <h6 style={{ fontSize: '12px', color: 'red' }}>{errorMessage}</h6>
                          )}
                        </div>
                      </IonGrid>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
