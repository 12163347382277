import { useState, useEffect } from 'react'
import axios from 'axios'

import { formatDateToISOString, getFormattedMediumDate, getFormattedMediumDateWithoutTimeZone } from '../utils'
import { IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonModal, IonSpinner } from '@ionic/react'
import StandardCenterCenter from '../commonComponents/StandardCenterContainer'
import { calendarOutline, chevronBack, chevronForward, closeOutline, timeOutline } from 'ionicons/icons'
import moment from 'moment-timezone'
import { colors } from '../theme/colors'
import './Dates.css'
import {
  containerStyle,
  headerStyle,
  headerTextStyle,
  datePickerContainerStyle,
  datePickerStyle,
  datePickerInnerStyle,
  weekDatesContainerStyle,
  timeSlotContainerStyle,
  subHeaderStyle,
  availableTimesContainerStyle,
  availableTimesInnerContainerStyle,
  timeSlotWrapperStyle,
  timeSlotStyle,
  timeIconContainerStyle,
  timeTextStyle,
  timeslotcontainerStyle,
  timeslotInnerContainerStyle,
  timesloticonStyle,
  timeslottextStyle,
  headerContainerStyle,
} from '../Scheduler/Styles/PickDateTimeStyles'

const styles = {
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
const formatDateToISO = (date) => {
  const pad = (n) => (n < 10 ? '0' + n : n)
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  const seconds = pad(date.getSeconds())
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

export default function StepPickDateTime({ businessId, updateScheduleData, step, stepNext, isWalkin, scheduleData, trackStep }) {

  const [dateAndTimeData, setDateAndTimeData] = useState()
  const [anyProfessionalDateAndTimeData, setAnyProfessionalDateAndTimeData] = useState({ loading: false, data: null })
  const [value, setValue] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate] = useState(moment(value))
  const [view, setView] = useState('week') // State to toggle between week and month view
  const [date, setDate] = useState(formatDateToISO(new Date()))
  const [selectedPeriod, setSelectedPeriod] = useState('')

  const handleMonthChange = (date) => {
    setView('week')
    setStartDate(moment(date))
    setDate(date)
    const dateValue = new Date(date).getDate()
    setValue(moment(date).startOf('month').date(dateValue).toDate())
    setIsLoading(true)
    setDateAndTimeData()
    setAnyProfessionalDateAndTimeData({ loading: true, data: null })
  }
  const handleNextWeek = () => {
    const nextWeek = moment(value).add(7, 'days')
    setValue(getFormattedMediumDateWithoutTimeZone(nextWeek.toDate()))
  }
  const handlePrevWeek = () => {
    const prevWeek = moment(value).subtract(7, 'days')
    setValue(getFormattedMediumDateWithoutTimeZone(prevWeek.toDate()))
  }
  function updateTime(dateValue) {
    setValue(dateValue)
    setIsLoading(true)
    setDateAndTimeData()
    setAnyProfessionalDateAndTimeData({ loading: true, data: null })
  }

  useEffect(() => {
    trackStep('PickDateTime', step, scheduleData)
  }, [])

  useEffect(() => {
    getAvailability()
    getAnyProfessionalAvaialbility()
  }, [value])

  const getAvailability = async () => {
    let services = scheduleData?.services.map((service) => service._id)
    if (!isWalkin) {
      let response = await axios.get(
        `/appointment_v2/getAvailable?businessId=${businessId}&locationId=${scheduleData?.location?._id || null}&barberId=${
          scheduleData?.anyProfessional ? 'undefined' : scheduleData?.barber?._id
        }&durationMin=${scheduleData?.durationMin}&customerId=${scheduleData?.customer?._id}&selectedDate=${getFormattedMediumDate(value)}&anyProfessional=${
          scheduleData?.anyProfessional
        }&services=${services}`
      )
      setDateAndTimeData(response.data)
      setIsLoading(false)
    }
  }

  const getAnyProfessionalAvaialbility = async () => {
    let services = scheduleData?.services.map((service) => service._id)

    if (!isWalkin && !scheduleData?.anyProfessional) {
      let response2 = await axios.get(
        `/appointment_v2/getAvailable?businessId=${businessId}&locationId=${scheduleData?.location?._id || null}&barberId=${'undefined'}&durationMin=${
          scheduleData?.durationMin
        }&customerId=${scheduleData?.customer?._id}&selectedDate=${getFormattedMediumDate(value)}&anyProfessional=${true}&services=${services}`
      )
      setAnyProfessionalDateAndTimeData({ loading: false, data: response2.data })
    } else {
      setAnyProfessionalDateAndTimeData({ loading: false, data: null })
    }
  }

  function handleTimeClick(data, isAnyProfessional) {
    if (isAnyProfessional) {
      updateScheduleData('dateTimeAnyProfessional', data)
    } else {
      updateScheduleData('dateTime', data)
    }

    stepNext()
  }
  const handleClick = (day) => {
    if (!isLoading) {
      updateTime(day.toDate())
    }
  }
  return (
    <>
      <div>
        {/* <HeaderComponent title={'Pick a time'} handleBack={stepBack} progress={{ total: totalSteps, step: step + 1 }} /> */}
        <DateTimeBody
          date={date}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          handleClick={handleClick}
          handlePrevWeek={handlePrevWeek}
          handleNextWeek={handleNextWeek}
          handleMonthChange={handleMonthChange}
          startDate={startDate}
          value={value}
          setValue={updateTime}
          dateAndTimeData={dateAndTimeData}
          handleTimeClick={handleTimeClick}
          isLoading={isLoading}
          anyProfessionalDateAndTimeData={anyProfessionalDateAndTimeData}
          barber={scheduleData?.barber}
          anyProfessional={scheduleData?.anyProfessional}
        />
      </div>
    </>
  )
}

function DateTimeBody({
  date,
  selectedPeriod,
  setSelectedPeriod,
  handleClick,
  handlePrevWeek,
  handleNextWeek,
  handleMonthChange,
  startDate,
  value,
  setValue,
  dateAndTimeData,
  handleTimeClick,
  isLoading,
  anyProfessionalDateAndTimeData,
  barber,
  anyProfessional,
}) {
  function handlePeriodClick(period) {
    setSelectedPeriod(period)
  }
  const filterAvailableTimes = (times) => {
    switch (selectedPeriod) {
      case 'Morning':
        return times.filter((slot) => moment(slot.startTime).hour() < 12)
      case 'Afternoon':
        return times.filter((slot) => moment(slot.startTime).hour() >= 12 && moment(slot.startTime).hour() < 16)
      case 'Evening':
        return times.filter((slot) => moment(slot.startTime).hour() >= 16)
      default:
        return times
    }
  }
  const startOfWeek = moment(value).startOf('week') // Start of the current selected week
  const endOfPrevWeek = moment(startOfWeek).subtract(1, 'day').endOf('day') // End of previous week
  const startOfNextWeek = moment(startOfWeek).add(1, 'week') // Start of next week
  const weekDays = [
    moment(endOfPrevWeek), // Previous week's last day
    ...Array.from({ length: 7 }, (_, i) => moment(startOfWeek).add(i, 'days')), // Current week
    moment(startOfNextWeek), // Next week's first day
  ]
  let subHeader = (
    <div style={styles.loadingBox}>
      <IonSpinner name='dots' />
    </div>
  )

  if (!isLoading) {
    subHeader = dateAndTimeData?.outOfBounds == true ? 'SCHEDULE NOT AVAILABLE YET' : dateAndTimeData?.locationOpen ? 'OPEN' : 'CLOSED'
  }

  let mainDateTimeMessage
  if (dateAndTimeData?.availableTimes?.length == 0) {
    mainDateTimeMessage = `No available appointments for ${
      anyProfessional ? 'any professional' : `${barber?.firstName} ${barber?.lastName?.charAt(0)}`
    }. Please check another day or check below for times from another professional.`
  }
 
  const isTodayOrLater = checkIsTodayOrLater(value) // Check if the date is today or later
  function checkIsTodayOrLater(date) {
    const today = new Date()
    // Set the hours to 0, 0, 0, 0 to compare only the date part
    today.setHours(0, 0, 0, 0)
    const chosenDate = new Date(date)
    const selectedDate = new Date(chosenDate.getFullYear(), chosenDate.getMonth(), chosenDate.getDate())
    return selectedDate >= today
  }
  return (
    <>
      <div style={containerStyle}>
        <div style={headerStyle}>
          <div style={headerTextStyle}>Select Date & Time</div>
        </div>
        <div style={datePickerContainerStyle}>
          <div style={datePickerStyle}>
            <div style={datePickerInnerStyle}>
              <div style={headerContainerStyle}>
                <div style={headerTextStyle}>
                  <IonDatetime
                    min={formatDateToISOString(new Date())}
                    mode='ios'
                    presentation='day'
                    value={date}
                    onIonChange={(e) => {
                      handleMonthChange(e.detail.value)
                    }}
                    style={{
                      alignSelf: 'center',
                      '--ion-font-size': '1.5rem',

                      color: 'black',
                      background: 'white',
                      minHeight: '20rem',
                    }}
                  ></IonDatetime>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isTodayOrLater && (
        <>
          <div style={timeSlotContainerStyle}>
            <div
              style={{
                alignSelf: 'stretch',
                flexDirection: 'column',
                justifyContent: subHeader == 'CLOSED' ? 'center' : 'flex-start',
                alignItems: subHeader == 'CLOSED' ? 'center' : 'flex-start',
                gap: 2,
                display: 'flex',
              }}
            >
              <div style={subHeaderStyle}>{subHeader}</div>
            </div>
          </div>

          {subHeader === 'CLOSED' ? (
            <StandardCenterCenter>
              <Closed />
            </StandardCenterCenter>
          ) : null}
          {subHeader == 'OPEN' ? (
            !isLoading && dateAndTimeData?.availableTimes?.length > 0 ? (
              <div style={availableTimesContainerStyle}>
                <h5
                  style={{
                    padding: '12px 25px 0px',
                    alignSelf: 'start',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'start',
                    fontFamily: 'Figtree',
                    marginBottom: -4,
                  }}
                >
                  Availability for {anyProfessional ? 'any professional' : `${barber?.firstName} ${barber?.lastName?.charAt(0)}`}
                </h5>
                {/* <div style={availableTimesInnerContainerStyle}>
                  <div style={timeSlotWrapperStyle}>
                    {['Morning', 'Afternoon', 'Evening'].map((period) => (
                      <div
                        key={period}
                        style={{
                          ...timeSlotStyle,
                          color: selectedPeriod === period ? '#0068DE' : '#717171', // Blue for selected, gray for others
                          border: selectedPeriod === period ? '1px #0068DE solid' : '1px #DADADA solid', // Blue border for selected, gray for others
                        }}
                        onClick={() => handlePeriodClick(selectedPeriod === period ? '' : period)}
                      >
                        <div style={timeIconContainerStyle}>
                          <IonIcon icon={timeOutline} />
                        </div>
                        <div style={{ ...timeTextStyle, color: selectedPeriod === period ? '#0068DE' : '#717171' }}>{period}</div>
                        {selectedPeriod === period && <IonIcon icon={closeOutline} />}
                      </div>
                    ))}
                  </div>
                </div> */}
                <div
                  style={{
                    alignSelf: 'stretch',
                    paddingLeft: 24,
                    paddingRight: 24,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 24,
                    display: 'flex',
                  }}
                >
                  <div style={{ width: '100%', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'flex-start', display: 'flex' }}>
                    {filterAvailableTimes(dateAndTimeData.availableTimes)?.map((timeSlot) => {
                      return (
                        <TimeSlotButton available={timeSlot.available} timeSlot={timeSlot} handleTimeClick={handleTimeClick} key={timeSlot.startTimeText} />
                      )
                    }) || null}
                  </div>
                </div>
              </div>
            ) : (
              <div style={availableTimesContainerStyle}>
                <h5
                  style={{
                    padding: '12px 25px 0px',
                    alignSelf: 'start',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'start',
                    fontFamily: 'Figtree',
                    marginBottom: -4,
                  }}
                >
                  Availability for {anyProfessional ? 'any professional' : `${barber?.firstName} ${barber?.lastName?.charAt(0)}`}
                </h5>
                <div style={{ ...availableTimesInnerContainerStyle, padding: '10px 20px' }}>
                  <MainDateMessage message={mainDateTimeMessage} />
                </div>
              </div>
            )
          ) : null}
          {!isLoading && anyProfessionalDateAndTimeData?.data?.availableTimes?.length > 0 ? (
            <div style={availableTimesContainerStyle}>
              <h5
                style={{
                  padding: '12px 25px 0px',
                  alignSelf: 'start',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'start',
                  fontFamily: 'Figtree',
                  marginBottom: -4,
                }}
              >
                Availability for Another Professional
              </h5>

              {/* <div style={availableTimesInnerContainerStyle}>
                <div style={timeSlotWrapperStyle}>
                  {['Morning', 'Afternoon', 'Evening'].map((period) => (
                    <div
                      key={period}
                      style={{
                        ...timeSlotStyle,
                        color: selectedPeriod === period ? '#0068DE' : '#717171', // Blue for selected, gray for others
                        border: selectedPeriod === period ? '1px #0068DE solid' : '1px #DADADA solid', // Blue border for selected, gray for others
                      }}
                      onClick={() => handlePeriodClick(selectedPeriod === period ? '' : period)}
                    >
                      <div style={timeIconContainerStyle}>
                        <IonIcon icon={timeOutline} />
                      </div>
                      <div style={{ ...timeTextStyle, color: selectedPeriod === period ? '#0068DE' : '#717171' }}>{period}</div>
                      {selectedPeriod === period && <IonIcon icon={closeOutline} />}
                    </div>
                  ))}
                </div>
              </div> */}
              <div
                style={{
                  alignSelf: 'stretch',
                  paddingLeft: 24,
                  paddingRight: 24,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 24,
                  display: 'flex',
                }}
              >
                <div style={{ width: '100%', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'flex-start', display: 'flex' }}>
                  {filterAvailableTimes(anyProfessionalDateAndTimeData?.data?.availableTimes)?.map((timeSlot) => {
                    return (
                      <TimeSlotButton
                        available={timeSlot.available}
                        timeSlot={timeSlot}
                        handleTimeClick={handleTimeClick}
                        key={timeSlot.startTimeText}
                        isAnyProfessional={true}
                      />
                    )
                  }) || null}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  )
}

const TimeSlotButton = ({ timeSlot, handleTimeClick, available, isAnyProfessional }) => {
  const activeColor = available ? '#222222' : 'red'
  if (!available) {
    return null
  }
  return (
    <div
      onClick={() => {
        handleTimeClick(timeSlot, isAnyProfessional)
      }}
      style={timeslotcontainerStyle}
    >
      <div style={timeslotInnerContainerStyle}>
        {/* <IonIcon icon={timeOutline} style={timesloticonStyle} /> */}
        <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
          <div style={timeslotInnerContainerStyle}>
            <div style={{ ...timeslottextStyle, color: activeColor }}>{timeSlot.startTimeText}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function MainDateMessage({ message }) {
  if (!message) return null
  return (
    <div style={{ backgroundColor: colors.primaryBlue25, border: `2px solid ${colors.primaryBlue}`, borderRadius: 8, padding: 20 }}>
      <StandardCenterCenter>{message}</StandardCenterCenter>
    </div>
  )
}

function Closed() {
  return (
    <div>
      <img src='/assets/closed.png' width={160} height={160} />
    </div>
  )
}
