import { useContext, useState, useEffect } from 'react'
import { IonContent, IonPage, IonImg, IonCol, IonRow, IonCard, IonIcon, IonGrid, IonRouterLink, IonSpinner } from '@ionic/react'
import './Home.css'
import Card from '../commonComponents/card'
import { AuthContext } from '../auth'
import AppointmentCard from '../commonComponents/appointmentCard'
import { calendar, chevronForward } from 'ionicons/icons'
import { colors } from '../theme/colors'
import CreateAccountCard from '../commonComponents/createAccountCard'
import StandardContainer from '../commonComponents/standardContainer'
import axios from 'axios'
import { isLocationOpen } from '../utils'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Home = ({ openLoginModal, openRegisterModal, openScheduleModal, openWalkinModal, appointments, locations, getAppointments }) => {
  const { userData, firebaseUser } = useContext(AuthContext)
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  let isSmall = vw > 500 ? false : true

  const setStatusBarStyleLight = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Light })
    } catch (error) {}
  }

  const setStatusBarStyleDark = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Dark })
    } catch (error) {}
  }

  useEffect(() => {
    setStatusBarStyleDark()
  }, [])

  const windowLocation = useLocation()

  useEffect(() => {
    if (windowLocation.pathname === '/home' || windowLocation.pathname === '/') {
      setStatusBarStyleDark()
    } else {
      setStatusBarStyleLight()
    }

    return setStatusBarStyleLight
  }, [windowLocation])

  return (
    <IonPage>
      <Helmet>
        <title>ClubCuts | Home</title>
      </Helmet>
      {/* <IonHeader>
        <IonToolbar color="white" style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
          <IonTitle class="ion-text-center">
            <img src='assets/logoLeftBlack.png' height={120} style={{ marginTop: -45, marginBottom: -50 }} />
          </IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen={true}>
        <HeroSectionCard2 isSubscribed={userData?.isSubscribed} userData={userData} openWalkinModal={openWalkinModal} locations={locations} />
        <StandardContainer padding={0}>
          {/* <Welcome userData={userData} isSmall={isSmall}/> */}
          <ActiveAppointmentCard appointments={appointments} getAppointments={getAppointments} />
          <HeroSectionCard isSubscribed={userData?.isSubscribed} />

          {/* <BookButton openScheduleModal={openScheduleModal} /> */}
          <CreateAccountCard firebaseUser={firebaseUser} openLoginModal={openLoginModal} openRegisterModal={openRegisterModal} />
          <FindLocation />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

function Welcome({ userData }) {
  if (!userData || userData == 'loading') {
    return null
  }
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 0,
      }}
    >
      <h1 style={{ marginLeft: 30, fontWeight: '700', fontSize: 35, marginBottom: 0, marginTop: 0 }}>{userData.firstName},</h1>
    </div>
  )
}

function HeroSectionCard({ isSmall, isSubscribed }) {
  if (isSubscribed) {
    return null
  }
  // return <HeroSection
  //   title="Barbershop & Haircut"
  //   description="Join ClubCuts & Get Unlimited Haircuts for a Flat Monthly Price"
  //   imageURL="/assets/closeUpHaircut.webp"
  //   isSmall={isSmall}
  // />
  return (
    <IonRouterLink routerLink='/subscription' routerDirection='none'>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingLeft: 15,
          paddingRight: 15,
          marginBottom: -20,
          marginTop: 10,
        }}
      >
        <Card>
          <IonImg style={{ width: '100%', height: 440, objectFit: 'cover' }} src={'/assets/hero3.png'}></IonImg>
        </Card>
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 600,
            height: '100%',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            color: '#fff',
            marginTop: 0,
          }}
        >
          {/* <h1 style={{ fontFamily: 'Montserrat, Arial', marginLeft: 30, marginTop: -20, fontWeight: '800', fontSize: 35, color:colors.gold }}>GRAND OPENING SALE</h1> */}
          <span
            style={{
              marginLeft: 30,
              fontFamily: 'Reenie Beanie',
              color: colors.green,
              fontSize: 25,
              marginBottom: 20,
            }}
          >
            #staycut
          </span>
          <h1
            style={{
              fontFamily: 'Montserrat, Arial',
              marginLeft: 30,
              marginTop: -20,
              fontWeight: '700',
              fontSize: 35,
            }}
          >
            UNLIMITED <br></br>HAIRCUTS.
          </h1>
          <p
            style={{
              fontFamily: 'Montserrat, Arial',
              marginLeft: 30,
              marginBottom: -20,
              fontWeight: '500',
            }}
          >
            Starting at
          </p>
          <h1
            style={{
              fontFamily: 'Montserrat, Arial',
              marginLeft: 30,
              fontWeight: '600',
              fontSize: 35,
            }}
          >
            $43<span style={{ fontSize: 14 }}>/mo (with prepay)</span>
          </h1>
          <div
            style={{
              fontFamily: 'Montserrat, Arial',
              padding: 10,
              marginLeft: 30,
              fontWeight: '600',
              fontSize: 16,
              border: '1px solid white',
              borderRadius: 6,
              width: 180,
              textAlign: 'center',
              marginTop: 20,
            }}
          >
            GET UNLIMITED
          </div>
        </div>
      </div>
    </IonRouterLink>
  )
}

function HeroSectionCard2({ isSmall, isSubscribed, userData, openWalkinModal, locations }) {
  const { businessData } = useContext(AuthContext)
  const history = useHistory()

  let height = 550
  if (!userData || userData == 'loading') {
    height = 480
  }

  let leftMargin = 30

  let locationOpenText = isLocationOpen(locations?.[0])

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: -50,
        backgroundColor: 'black',
      }}
    >
      <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1 }} src={'/assets/blackBackground.png'}></IonImg>
      <StandardContainer padding={0}>
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            color: '#fff',
            marginTop: 0,
            width: '100%',
            maxWidth: 800,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 50,
              width: '100%',
              maxWidth: 800,
            }}
          >
            <img src='assets/logos/CLUBCUTS_WHITE.png' height={50} width={80} style={{}} />
          </div>
          <span
            style={{
              marginLeft: leftMargin,
              fontFamily: 'Reenie Beanie',
              color: colors.green,
              fontSize: 25,
              marginTop: 10,
            }}
          >
            #staycut
          </span>
          <Welcome userData={userData} />
          <h1
            style={{
              marginLeft: leftMargin,
              marginTop: 0,
              fontWeight: '700',
              fontSize: 35,
              marginBottom: -4,
            }}
          >
            Welcome to <br></br>
            {businessData?.name}
          </h1>
          <p
            style={{
              fontFamily: 'Montserrat, Arial',
              marginLeft: leftMargin,
              marginBottom: -30,
              fontWeight: '500',
            }}
          >
            The haircut you've always wanted is just a few clicks away!{' '}
          </p>
          <br></br>
          <br></br>
          <IonRouterLink routerLink='/schedule' routerDirection='none'>
            <div
              style={{
                fontFamily: 'Montserrat, Arial',
                padding: 10,
                marginLeft: leftMargin,
                fontWeight: '600',
                fontSize: 16,
                color: 'black',
                backgroundColor: colors.green,
                border: `1px solid ${colors.green}`,
                borderRadius: 6,
                width: 250,
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              Book Now
            </div>
          </IonRouterLink>
          {locationOpenText == 'Open Now' ? (
            <div
              onClick={openWalkinModal}
              style={{
                fontFamily: 'Montserrat, Arial',
                padding: 10,
                marginLeft: leftMargin,
                fontWeight: '600',
                fontSize: 16,
                color: 'black',
                backgroundColor: 'white',
                border: `1px solid white`,
                borderRadius: 6,
                width: 250,
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              I'm a Walk-in
            </div>
          ) : locationOpenText?.includes('Opens at') ? (
            <div
              style={{
                fontFamily: 'Montserrat, Arial',
                padding: 10,
                marginLeft: leftMargin,
                fontWeight: '600',
                fontSize: 16,
                color: 'black',
                backgroundColor: 'white',
                border: `1px solid white`,
                borderRadius: 6,
                width: 250,
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              Walk-ins: {locationOpenText}
            </div>
          ) : (
            <div
              style={{
                fontFamily: 'Montserrat, Arial',
                padding: 10,
                marginLeft: leftMargin,
                fontWeight: '600',
                fontSize: 16,
                color: 'white',
                backgroundColor: 'transparent',
                border: `1px solid transparent`,
                borderRadius: 6,
                width: 250,
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              {locationOpenText}
            </div>
          )}
        </div>
      </StandardContainer>
    </div>
  )
}

function BookButton({ openScheduleModal }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Card showBorder={true} onClick={openScheduleModal}>
        <div
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
          }}
        >
          <IonIcon icon={calendar} color='primary' style={{ height: 20, width: 20, margin: 10 }} />
          <div style={{ fontSize: 20, fontWeight: 600, color: 'black' }}>Book Appointment</div>
        </div>
      </Card>
    </div>
  )
}

function FindLocation() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 0 }}>
      <Card routerLink='/location' routerDirection='none'>
        <div
          style={{
            textAlign: 'left',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
          }}
        >
          <IonCard style={{ margin: '-10px 10px -10px -10px' }}>
            <IonImg src='/assets/map.png' style={{ height: 120, width: 120 }} />
          </IonCard>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div>
                  <div style={{ fontSize: 19, fontWeight: 600, color: 'black' }}>Find our Location</div>
                  <div style={{ fontSize: 16, fontWeight: 500, color: 'black' }}>nearest to you</div>
                </div>
              </IonCol>
              <IonCol size='auto' style={{ alignItems: 'center', display: 'flex' }}>
                <IonIcon icon={chevronForward} style={{ height: 20, width: 20 }} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </Card>
    </div>
  )
}

function ActiveAppointmentCard({ appointments, getAppointments }) {
  return (
    <>
      {appointments &&
        appointments.map((a) => {
          if (a.status == 'Complete' || a.status == 'Closed' || a.status == 'Canceled' || a.status == 'No Show') {
            return null
          }

          if (a?.type == 'Walkin') {
            return <WalkinCard appointment={a} key={a._id} getAppointments={getAppointments} />
          } else {
            return <AppointmentCard appointment={a} key={a._id} />
          }
        })}
    </>
  )
}

function WalkinCard({ appointment, getAppointments }) {
  let [isCanceling, setIsCanceling] = useState(false)
  async function cancelWalkin(id) {
    try {
      setIsCanceling(true)
      if (window.confirm('Are you sure you want to cancel your appointment?')) {
        setIsCanceling(true)
        await axios.patch(`/appointment_v2/cancel`, { appointmentId: id })
        await getAppointments()
        setIsCanceling(false)
      } else {
        setIsCanceling(false)
      }
    } catch (error) {
      setIsCanceling(false)
      alert('Sorry there was an issue. Please try again later (CA-1001)')
    }
  }

  return (
    <div key={appointment._id} style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: -20 }}>
      <Card>
        {/* <div style={{display:'flex', flexDirection:'row'}}>
          {/* <div>
            <img src="/assets/blackTimeChip.png" width={100} height={100} style={{marginRight:10}}/>
          </div> 
          <div>
            <h1>Checked In</h1>
              <p>Kick back and relax. Grab your self a drink and some snacks.</p>
              <p>Someone will be with you when a spot opens!</p>
          </div>
        </div> */}
        <IonGrid style={{ color: 'black', padding: 10 }}>
          <IonRow>
            <IonCol size='auto'>
              <img src='/assets/blackTimeChip.png' width={80} height={80} style={{ marginRight: 10 }} />
            </IonCol>
            <IonCol style={{}}>
              <h1 style={{ marginTop: 0 }}>Checked In</h1>
              <p>Kick back and relax. Grab yourself a drink and some snacks.</p>
              <p>Someone will be with you when a spot opens!</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              onClick={() => {
                if (!isCanceling) {
                  cancelWalkin(appointment?._id)
                }
              }}
            >
              <div
                style={{
                  fontFamily: 'Montserrat, Arial',
                  padding: 10,
                  fontWeight: '600',
                  fontSize: 16,
                  color: 'black',
                  backgroundColor: '#f2f2f2',
                  border: `1px solid white`,
                  borderRadius: 6,
                  width: '100%',
                  textAlign: 'center',
                  marginTop: 10,
                }}
              >
                {isCanceling ? <IonSpinner name='dots' /> : 'Cancel Walk-in'}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </Card>
    </div>
  )
}

export default Home
