import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import axios from 'axios'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { personCircle } from 'ionicons/icons'
import { useContext, useState } from 'react'
import { trackCreatedAccount, trackError } from './analytics'
import { AuthContext } from './auth'
import PhoneNumberTextField from './commonComponents/phoneNumberTextField'

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/
  return re.test(String(email).toLowerCase())
}

const Register = ({ cancel, switchView, removeHeader, headerMessage, complete }) => {
  const { getUserData, businessData, businessId } = useContext(AuthContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [iserror, setIserror] = useState(false)
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [notificationsEnabled, setNotificationsEnabled] = useState(true)

  const handleCancel = () => {
    cancel()
  }

  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled)
  }

  const handleLogin = async () => {
    setSending(true)
    if (!email) {
      setMessage('Please enter a valid email')
      setIserror(true)
      setSending(false)
      return
    }
    if (!firstName || firstName === '') {
      setMessage('Your first name is missing')
      setIserror(true)
      setSending(false)
      return
    }
    if (!lastName || lastName === '') {
      setMessage('Your last name is missing')
      setIserror(true)
      setSending(false)
      return
    }
    if (validateEmail(email) === false) {
      setMessage('Your email is invalid')
      setIserror(true)
      setSending(false)
      return
    }

    if (!phoneNumber || phoneNumber === '') {
      setMessage('Your Phone Number is Missing')
      setIserror(true)
      setSending(false)
      return
    }

    if (!password || password.length < 6) {
      setMessage('Please enter a longer password')
      setIserror(true)
      setSending(false)
      return
    }

    if (!businessId) {
      setMessage('Critical Error')
      setIserror(true)
      setSending(false)
    }

    try {
      const auth = getAuth()
      if (businessData?.firebaseTenantId === 'clubcuts') {
        //do nothing
      } else if (businessData?.firebaseTenantId) {
        auth.tenantId = businessData.firebaseTenantId
      } else {
        throw new Error('No Tenant ID')
      }

      let response = await createUserWithEmailAndPassword(auth, email, password)
      const source = sessionStorage.getItem('refid') // Retrieve the source from session storage
      let customerInput = {
        firstName,
        lastName,
        email,
        phoneNumber,
        firebaseId: response.user.uid,
        businessId,
        notificationSettings: {
          textNotificationsEnabled: notificationsEnabled,
          emailNotificationsEnabled: notificationsEnabled,
        },
      }
      if (source) {
        customerInput.source = source
      }
      await axios.post('/customer_v2', customerInput)
      getUserData()
      setSending(false)
      trackCreatedAccount()
      complete()
    } catch (error) {
      console.log(error)
      if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
        setMessage('This email is already in use')
      } else {
        setMessage(`Registration failure! Please try again soon: ${error?.code || ''}`)
      }
      trackError('Register', error?.message, error?.code)
      setIserror(true)
      setSending(false)
    }
  }

  let inputStyle = { border: '1px solid #d6d6d6', borderRadius: '10px', paddingLeft: '10px', paddingRight: '10px', width: '100%', marginBottom: 10, paddingTop: 4, paddingBottom: 4 }

  return (
    <>
      {removeHeader ? null : (
        <IonHeader>
          <IonToolbar color='white'>
            <IonTitle>Register</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={handleCancel}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent fullscreen className='ion-padding ion-text-center'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h2 style={{ marginTop: -20 }}>{headerMessage}</h2>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass='my-custom-class'
                header={'Error!'}
                message={message}
                buttons={['Dismiss']}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon style={{ fontSize: '70px', color: '#0040ff' }} icon={personCircle} />
            </IonCol>
          </IonRow>
          <IonRow style={{ margin: 0, padding: 0 }}>
            <IonCol style={{ margin: 0, padding: 0 }}>
              <IonItem lines='none'>
                <IonLabel position='stacked'>First Name</IonLabel>
                <div style={inputStyle}>
                  <IonInput fill="solid" type='text' placeholder='First Name' value={firstName} onIonInput={(e) => setFirstName(e.target.value)} />
                </div>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ margin: 0, padding: 0 }}>
            <IonCol style={{ margin: 0, padding: 0 }}>
              <IonItem lines='none'>
                <IonLabel position='stacked'>Last Name</IonLabel>
                <div style={inputStyle}>
                  <IonInput type='text' placeholder='Last Name' value={lastName} onIonInput={(e) => setLastName(e.target.value)} />
                </div>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ margin: 0, padding: 0 }}>
            <IonCol style={{ margin: 0, padding: 0 }}>
              <IonItem lines='none'>
                <IonLabel position='stacked'>Phone Number</IonLabel>
                <div style={inputStyle}>
                  <PhoneNumberTextField value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ margin: 0, padding: 0 }}>
            <IonCol style={{ margin: 0, padding: 0 }}>
              <IonItem lines='none'>
                <IonLabel position='stacked'>Email</IonLabel>
                <div style={inputStyle}>
                  <IonInput type='email' placeholder='Email' value={email} onIonInput={(e) => setEmail(e.target.value)} />
                </div>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow style={{ margin: 0, padding: 0 }}>
            <IonCol style={{ margin: 0, padding: 0 }}>
              <IonItem lines='none'>
                <IonLabel position='stacked'>Password</IonLabel>
                <div style={inputStyle}>
                  <IonInput type='password' placeholder='Password' value={password} onIonInput={(e) => setPassword(e.target.value)} />
                </div>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonGrid>
                <IonRow>
                  <IonCol size='auto'>
                    <IonCheckbox mode='ios' checked={notificationsEnabled} onIonChange={toggleNotifications} labelPlacement='end' />
                  </IonCol>
                  <IonCol>
                    <div style={{ textAlign: 'left', fontSize: 11 }}>
                      I agree to receive texts & emails reminding me about my appointment and marketing messages. Data rates may apply. Go to notification
                      settings in profile to opt out.
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p style={{ fontSize: 'small' }}>
                By clicking REGISTER you agree to our <a href='https://www.clubcuts.com/terms-and-conditions'>Policy</a>
              </p>
              <IonButton disabled={sending} expand='block' onClick={handleLogin} style={{ marginLeft: 10, marginRight: 10 }}>
                {sending ? <IonSpinner name='dots' /> : 'Register'}
              </IonButton>
              <p style={{ fontSize: 'medium' }}>
                Already a Member?{' '}
                <a
                  onClick={() => {
                    switchView()
                  }}
                >
                  Login
                </a>
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </IonContent>
    </>
  )
}

export default Register
