import React, { useState, useContext, useEffect } from 'react';
import { IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonAvatar, IonItem, IonLabel, IonList } from '@ionic/react';
import { AuthContext } from '../../auth'
import axios from 'axios'

function AccountActionsModal({ isOpen, close }) {
    const { logout } = useContext(AuthContext);

    async function deleteAccount() {
        if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
            try {
                await axios.delete('/customer_v2')
                alert("Done")
                logout()
                close() 
            } catch (error) {
                alert("Sorry there was an error please try again soon. (DUS-1000)")
            }
            
        }
    }


    return (

        <IonModal isOpen={isOpen} >
            <IonHeader>
                <IonToolbar color='white' style={{ backgroundColor: 'white' }}>
                    <IonTitle>Account Actions</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={close}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className='regularBackground'>
                <h6 style={{ color: 'grey', marginLeft: 20 }}>AccountActions</h6>
                <IonList inset={true}>
                    <IonItem button onClick={() => { deleteAccount() }}>
                        <IonLabel>
                            Delete Account
                        </IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonModal>
    );
}

export default AccountActionsModal;